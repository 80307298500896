import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";
import "./PureReactCarousel.css";
import { useEffect, useRef } from "react";
const PureReactCarousel = (props) => {
  const ref = useRef();
  useEffect(async () => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        props.setShowCarousel(!props.showCarousel);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div
      className="carousel__container"
      // onClick={() => {
      //   props.setShowCarousel(!props.showCarousel);
      // }}
      ref={ref}
    >
      <Carousel
        width={50}
        emulateTouch={true}
        showThumbs={false}
        dynamicHeight={true}
      >
        {props.carouselContent?.map((image) => {
          return (
            <div>
              <img
                src={
                  props.type === "Room"
                    ? process.env.REACT_APP_API + "/rooms/" + image
                    : process.env.REACT_APP_API + "/hotels/" + image
                }
                className="carousel_img"
              />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default PureReactCarousel;
