import React from "react";
import DetailCont from "../DetailCont";
import Loading from "../../AssetComponents/Loading/Loading";

function RoomDetails(props) {
  const { rooms } = props;
  return (
    <div className="hotel_details_container">
      {props.loading ? (
        <Loading />
      ) : (
        rooms.map((room) => {
          return (
            <DetailCont
              type="Room"
              coverImage={room.coverImage}
              title={room.hotel?.name}
              location={
                "Lebanon, " + room.hotel?.location?.district?.name + " District"
              }
              rooms={room}
              hotel_id={room.hotel._id}
              available={room.availability}
              Id={room?._id}
              hotelId={room.hotel?._id}
            />
          );
        })
      )}
    </div>
  );
}

export default RoomDetails;
