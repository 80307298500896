/** @format */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: [],
  loading: false,
  error: "",
};

const notificationsSlice = createSlice({
  initialState,
  name: "notifications",
  reducers: {
    fetchNotificationsRequest(state) {
      state.loading = true;
    },
    fetchNotificationsSuccess(state, action) {
      state.loading = false;
      state.notifications = action.payload;
    },
    fetchNotificationsFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    addNotification(state, action) {
      state.notifications.unshift(action.payload);
    },
    readAllNotifications(state, action) {
      state.notifications.forEach((n, i) => {
        n.read = true;
      });
    },
    deleteAllNotifications(state, action) {
      state.notifications = [];
    },
  },
});

export const notificationsActions = notificationsSlice.actions;
export default notificationsSlice;
