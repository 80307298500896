/** @format */

import React, { useState } from "react";
import "./RoomCart.scss";
import HeartComponent from "../HeartComponent";

function RoomCart(props) {
  const handelShowCarousel = () => {
    if (props.RoomId) props.setRoomId(props.RoomId);
    props.setShowRoomCarousel(!props.showRoomCarousel);
  };
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };
  return (
    <div
      className={
        props.available != 0
          ? props.border
            ? "room_details_cont pointer"
            : "no_border pointer"
          : "no_border not_pointer"
      }
      onClick={props.available != 0 && props.onClick}
    >
      {props.img && (
        <img
          src={props.img}
          alt=""
          className={props.available != 0 ? "room_img" : "room_img not_av"}
          onClick={(e) => {
            e.stopPropagation();
            handelShowCarousel();
          }}
        />
      )}
      <div className={props.available != 0 ? "room_left" : "room_left not_av"}>
        <div>
          <span className="room_type">{props.roomType}</span>
          <br />
          <span className="room_feedback">
            {props.description?.length > 200 ? (
              showMore ? (
                <div>
                  {props.description}
                  <span className="show_more" onClick={handleShowMore}>
                    {" "}
                    ...show less
                  </span>
                </div>
              ) : (
                <div>
                  {props.description.substring(0, 185)}
                  <span className="show_more" onClick={handleShowMore}>
                    {" "}
                    ...show more
                  </span>
                </div>
              )
            ) : (
              props.description
            )}
          </span>
        </div>

        <div className="room_cart_cont">
          {props.amenities?.map((av, i) => {
            return (
              <img
                className="amenityImg"
                src={process.env.REACT_APP_API + "/amenities/" + av.icon}
              />
            );
          })}
        </div>
      </div>
      <div className="room_right">
        <div className="upper">
          <HeartComponent Room_Id={props.RoomId} />

          {/* <HeartComponent Room_Id={props._id} /> */}
        </div>

        <div className={props.available != 0 ? "lower" : "lower not_av"}>
          {props.discount == 0 ? (
            <div className="room_cart_price">
              {" "}
              <span>${props.price.toFixed(2)} </span>
              /day{" "}
            </div>
          ) : (
            <div className="cont2_room_price_offer">
              <div className="room_cart_price">
                <span>
                  $
                  {(props.price - props.price * (props.discount / 100)).toFixed(
                    2
                  )}{" "}
                </span>
                /day
              </div>
              <div className="room_cart_discount_cont">
                <div className="room_discount">-{props.discount}%</div>
                <p>${props.price} per night</p>
              </div>
            </div>
          )}

          <p>Pay at the Hotel</p>
        </div>
      </div>
    </div>
  );
}

export default RoomCart;
