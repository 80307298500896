/** @format */

import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { userReducer } from "./user/userReducer";
import { tagsReducer } from "./tags/tagsReducer";
import { hotelsReducer } from "./hotels/hotelsReducer";
import { testimonialsReducer } from "./testimonials/testimonialsReducer";
import { faqReducer } from "./faq/faqReducer";
import { hotelReducer } from "./hotel/hotelReducer";
import { districtsReducer } from "./districts/districtsReducer";
import { amenitiesReducer } from "./amenities/amenitiesReducer";
import { roomsReducer } from "./rooms/roomsReducer";
import { filtersReducer } from "./filters/filtersReducer";
import bookingsSlice from "./bookings/bookingsReducer";
import wishlistSlice from "./wishlist/wishListReducer";
import notificationsSlice from "./notifications/notificationsReducer";

const reducer = combineReducers({
  userReducer: userReducer,
  tagsReducer: tagsReducer,
  hotelsReducer: hotelsReducer,
  testimonialsReducer: testimonialsReducer,
  faqReducer: faqReducer,
  hotelReducer: hotelReducer,
  districtReducer: districtsReducer,
  amenitiesReducer: amenitiesReducer,
  roomsReducer: roomsReducer,
  filtersReducer: filtersReducer,
  bookingsReducer: bookingsSlice.reducer,
  wishlistReducer: wishlistSlice.reducer,
  notificationsReducer: notificationsSlice.reducer,
});

const middleware = [thunk];

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
