/** @format */

import React from "react";
import { MdLocationOn } from "react-icons/md";
import "./HotelInWishlist.scss";
import HeartComponent from "../AssetComponents/HeartComponent";
function RoomInWishlist(props) {
  return (
    <>
      <div className={"Hotel_detail_container"}>
        <div className="detail_sub_container1">
          <img
            src={
              process.env.REACT_APP_API +
              (props.type == "Room" ? "/rooms/" : "/hotels/") +
              props.rooms?.coverImage
            }
          />
        </div>
        {props.type == "Room" && (
          <div className="detail_sub_room">
            <div className="sub_room_header">
              <div className="sub_hotel_header_info">
                <div className="sub_room_title">
                  {props.rooms?.hotel?.name}{" "}
                </div>
                <div className="sub_room_location">
                  <i>
                    <MdLocationOn size={"0.8rem"} />{" "}
                  </i>
                  {props.rooms?.hotel?.location?.district.name}
                </div>
              </div>
              <HeartComponent Room_Id={props.rooms?._id} />
            </div>
            <div className="sub_room_cont2">
              <div className="sub_cont2_container">
                <div className="sub_cont2_main_info">
                  <div className="sub_cont2_header">
                    <div className="title">{props.rooms?.type} Room </div>
                    <div className="subtitle">
                      {props.rooms?.description.substring(0, 100)}...
                    </div>
                  </div>
                  <div className="cont2_sub_info">
                    <div className="amenties_list">
                      {props.rooms?.amenities.map((av, i) => {
                        return (
                          <img
                            className={"amenityImg"}
                            src={
                              process.env.REACT_APP_API +
                              "/amenities/" +
                              av.icon
                            }
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                {props.rooms?.discount == 0 ? (
                  <div className="cont2_sub_room_price">
                    ${props.rooms.price}
                    <span> /day </span>
                  </div>
                ) : (
                  <div className="cont2_room_price_offer">
                    <div className="cont2_sub_room_price">
                      $
                      {props.rooms?.price -
                        props.rooms?.price * (props.rooms?.discount / 100)}
                      <span> /day </span>
                    </div>
                    <div className="room_offer_cont">
                      <div className="room_offer">
                        -{props.rooms?.discount}%
                      </div>
                      <p>${props.rooms?.price} per night</p>
                    </div>
                  </div>
                )}
              </div>

              <div className="cont2_room_footer">
                {" "}
                see rooms from this hotel
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default RoomInWishlist;
