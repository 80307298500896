import {
  FETCH_TAGS_REQUEST,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_FAIL,
  FETCH_ACTIVE_TAGS_FAIL,
  FETCH_ACTIVE_TAGS_REQUEST,
  FETCH_ACTIVE_TAGS_SUCCESS,
} from "./tagsTypes";
import axios from "axios";
const tagsUrl = process.env.REACT_APP_API + "/tag";
export const getGlobalTags = () => async (dispatch) => {
  dispatch({
    type: FETCH_TAGS_REQUEST,
  });
  try {
    const res = await axios.get(tagsUrl + "?isGlobal=true");
    dispatch({
      type: FETCH_TAGS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: FETCH_TAGS_FAIL,
    });
  }
};

export const getActiveTags = () => async (dispatch) => {
  dispatch({
    type: FETCH_ACTIVE_TAGS_REQUEST,
  });
  try {
    const res = await axios.get(tagsUrl + "?isActive=true");

    dispatch({
      type: FETCH_ACTIVE_TAGS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: FETCH_ACTIVE_TAGS_FAIL,
      payload: "error occured",
    });
  }
};
