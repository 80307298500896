import { useEffect, useState } from "react";
import { getGlobalTags } from "../../redux/tags/tagsActions";
import { useDispatch, useSelector } from "react-redux";
import { getDistricts } from "../../redux/districts/districtsActions";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Loading from "../AssetComponents/Loading/Loading";
import { FaCrown } from "react-icons/fa";

function HomeSearch() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { districts, loading: districtsLoading } = useSelector(
    (state) => state.districtReducer
  );
  const { tags, loading: tagsLoading } = useSelector(
    (state) => state.tagsReducer
  );
  const [selectedTags, setSelectedTags] = useState([]);

  const [selectedDistrict, setSelectedDistrict] = useState({
    value: "",
    label: "Districts",
  });

  useEffect(() => {
    if (tags.length === 0) dispatch(getGlobalTags());
    if (districts.length === 0) dispatch(getDistricts());
  }, []);

  const addTag = (id) => {
    setSelectedTags([...selectedTags, id]);
  };

  const removeTag = (id) => {
    const newSelectedTags = selectedTags.filter((tag) => {
      return tag != id;
    });
    setSelectedTags([...newSelectedTags]);
  };

  const navigateToExplore = () => {
    navigate("/explore", {
      state: {
        exploreType: "rooms",
        defaultDistrict: selectedDistrict,
        defaultTags: selectedTags,
        outerInit: true,
      },
    });
  };
  return (
    <div className="Home_SearchContainer">
      <div className="search_sub">
        <div className="select-wrapper">
          <select
            onChange={(e) => {
              districts.find((d) => {
                if (d._id === e.target.value) {
                  setSelectedDistrict({
                    value: d._id,
                    label: d.name,
                  });
                }
              });
            }}
          >
            <option>Select your district</option>
            {districtsLoading ? (
              <Loading />
            ) : (
              districts.map((district) => {
                return <option value={district._id}>{district.name}</option>;
              })
            )}
          </select>
        </div>
        <button
          className="Home_SearchButton_Mobile btn"
          onClick={navigateToExplore}
        >
          <FaSearch style={{ color: "white" }} size={25} />
        </button>
      </div>
      <div className="Home_SearchSubContainer">
        <div className="Home_TagsContainer">
          {tagsLoading ? (
            <Loading />
          ) : (
            tags.map((tag, i) => {
              const isSelected = selectedTags.find((selectedTag) => {
                return selectedTag == tag._id;
              });
              {
                console.log(tags);
              }
              if (isSelected)
                return (
                  <div
                    className="Home_Tag activeTag"
                    onClick={() => {
                      removeTag(tag._id);
                    }}
                    key={i}
                  >
                    <div className="tag_name">
                      #{tag.name}
                      {tag.isHot && (
                        <div className="filter_crown_home">
                          <FaCrown className="crown_icon" size={"1rem"} />
                        </div>
                      )}
                    </div>
                  </div>
                );
              return (
                <div
                  className="Home_Tag inactiveTag"
                  onClick={() => {
                    addTag(tag._id);
                  }}
                  key={i}
                >
                  <div className="tag_name">
                    #{tag.name}
                    {tag.isHot && (
                      <div className="filter_crown_home">
                        <FaCrown className="crown_icon" size={"1rem"} />
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          )}
        </div>
        <button className="Home_SearchButton btn" onClick={navigateToExplore}>
          <FaSearch style={{ color: "white" }} size={25} />
        </button>
      </div>
    </div>
  );
}
export default HomeSearch;
