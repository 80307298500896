/** @format */

import {
  TRENDING_HOTELS_REQUEST,
  TRENDING_HOTELS_SUCCESS,
  TRENDING_HOTELS_FAIL,
  DISTRICT_HOTELS_REQUEST,
  DISTRICT_HOTELS_SUCCESS,
  DISTRICT_HOTELS_FAIL,
} from "./hotelsTypes";
import axios from "axios";

export const getTrendingHotels = () => async (dispatch) => {
  dispatch({
    type: TRENDING_HOTELS_REQUEST,
  });
  try {
    const res = await axios.get(
      process.env.REACT_APP_API + "/hotel?isGlobal=true"
    );
    dispatch({
      type: TRENDING_HOTELS_SUCCESS,
      payload: res.data.hotels,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: TRENDING_HOTELS_FAIL,
    });
  }
};

export const getDiscHotel = (id) => async (dispatch) => {
  dispatch({
    type: DISTRICT_HOTELS_REQUEST,
  });
  try {
    const res = await axios.get(
      process.env.REACT_APP_API + "/hotel?district=" + id
    );
    dispatch({
      type: DISTRICT_HOTELS_SUCCESS,
      payload: res.data.hotels,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: DISTRICT_HOTELS_FAIL,
    });
  }
};




