import React from "react";

function ConfirmRoomPreview(props) {
  return (
    <div className="ConfirmRoomPreview">
      <div className="left">
        <div className="room_type">{props.room?.type} Room</div>
        <div className="room_description">{props.room?.description}</div>
        <div className="room_amenities">
          {props.room?.amenities.map((amenity, i) => {
            return (
              <img
                className="amenityImg"
                src={process.env.REACT_APP_API + "/amenities/" + amenity.icon}
                key={i}
              />
            );
          })}
        </div>
      </div>
      <div className="right">
        <div className="upper"></div>

        <div className="lower">
          <div className="price">
            <span>${props.price}</span>
            /day
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmRoomPreview;
