/** @format */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Support.scss";
import home_img from "../../assets/images/home_img.webp";
import luxuryspa from "../../assets/images/luxuryspa.webp";
import "react-multi-carousel/lib/styles.css";
import Faq from "./Faq";
import { getFaqs } from "../../redux/faq/faqActions";
import EmailSubscription from "../Home/EmailSubscription";

function Support() {
  const dispatch = useDispatch();
  const faqReducer = useSelector((state) => state.faqReducer);

  // useEffect;
  // const [selectedTags, setSelectedTags] = useState([]);
  // const addTag = (tag) => {
  //   for (let i = 0; i < selectedTags.length; i++) {
  //     if (selectedTags[i] === tag) {
  //       setSelectedTags([
  //         ...selectedTags.slice(0, i),
  //         ...selectedTags.slice(i + 1),
  //       ]);
  //       return;
  //     }
  //   }
  //   setSelectedTags([...selectedTags, tag]);
  // };
  useEffect(() => {
    dispatch(getFaqs());
  }, []);
  return (
    <div className="Support_Container globalContainer">
      <div className="Support_Bg">
        {/* First Part */}
        <div className="luxury_spa">
          <img src={luxuryspa} alt="" />
        </div>
        <div className="Support_Welcome">
          <h1 className="title">Need help?</h1>
          <p className="paragraph">
            Facing difficulties to book a room?
            <br />
            Don't worry we got you covered!
            <br />
            Check out our FAQ Section,
            <br className="add-mobile" /> if that didn't help you
            <br /> can always contact us.
          </p>
          <a
            href="https://wa.me/12133733555"
            target={"_blank"}
            className="contact_link"
          >
            <button className="btn cont-btn">Contact Support</button>
          </a>
        </div>
        <div className="Support_Img">
          <img src={home_img} alt="" />
        </div>
      </div>
      {/* FAQ's */}
      <div className="faq_title_ title">Frequently Asked Questions</div>
      <div className="faq_container">
        {faqReducer.faqs?.map((f, i) => {
          return <Faq q={f.question} a={f.answer} />;
        })}
      </div>
      {/* Email Subscriptions */}
      <EmailSubscription />
    </div>
  );
}

export default Support;
