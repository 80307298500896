/** @format */

import React, { useState } from "react";
import "./HotelCart.scss";
import { FaHeart, FaMapMarkerAlt, FaRegHeart } from "react-icons/fa";

import StarRatings from "react-star-ratings";
import HeartComponent from "../AssetComponents/HeartComponent";
import { useNavigate } from "react-router-dom";

function HotelCart({ src, name, locationName, rating, Id }) {
  const navigate = useNavigate();
  return (
    <div className="HotelCart_Container">
      <img
        className="HotelCart_Img"
        onClick={() => navigate("/hotel/" + Id)}
        src={src}
        alt=""
      />
      <div className="HotelCart_Name">
        {name}
        {/* <br />
        {locationName} */}
      </div>
      <div className="HotelCart_Location">
        <FaMapMarkerAlt style={{ color: "var(--primary-color)" }} />
        <div className="HotelCart_LocationName">{locationName}, Lebanon</div>
      </div>
      <div className="HotelCart_RatingRow">
        <StarRatings
          rating={rating}
          starRatedColor={"#FFD700"}
          numberOfStars={Math.floor(rating)}
          name="rating"
          starDimension={"15"}
          starSpacing={"0"}
        />

        <HeartComponent Hotel_Id={Id} />
      </div>
    </div>
  );
}

export default HotelCart;
