import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Home.scss";
import { FaArrowRight, FaMapMarkerAlt } from "react-icons/fa";
import { BsClock } from "react-icons/bs";
import { FiCreditCard } from "react-icons/fi";
import home_img from "../../assets/images/home_img.webp";
import HotelCart from "./HotelCart";
import HotelSign from "../../assets/images/Hotel_Sign.png";
import luxuryspa from "../../assets/images/luxuryspa.webp";
import StarRatings from "react-star-ratings/build/star-ratings";
import Testimonial from "./Testimonial";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import EmailSubscription from "./EmailSubscription";
import { getTrendingHotels } from "../../redux/hotels/hotelsActions";
import { useNavigate } from "react-router-dom";
import { getGlobalTestimonials } from "../../redux/testimonials/testimonialsActions";
import { getTrendingRooms } from "../../redux/rooms/roomsActions";
import { getDistricts } from "../../redux/districts/districtsActions";
import HomeSearch from "./HomeSearch";
import HeartComponent from "../AssetComponents/HeartComponent";
import Loading from "../AssetComponents/Loading/Loading";

function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { hotels, loading: hotelsLoading } = useSelector(
    (state) => state.hotelsReducer
  );
  const { testimonials, loadingTestimonials } = useSelector(
    (state) => state.testimonialsReducer
  );
  const { rooms, loading: roomLoading } = useSelector(
    (state) => state.roomsReducer
  );
  const { districts } = useSelector((state) => state.districtReducer);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1200, min: 768 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const responsiveRoom = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1200, min: 768 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    if (hotels.length === 0) dispatch(getTrendingHotels());
    if (rooms.length === 0) dispatch(getTrendingRooms());
    if (districts.length === 0) dispatch(getDistricts());
  }, []);
  useEffect(() => {
    dispatch(getGlobalTestimonials());
  }, []);

  return (
    <div className="Home_Container globalContainer">
      <div className="Home_Bg">
        {/* First Part */}
        <div className="luxury_spa">
          <img src={luxuryspa} alt="" />
        </div>
        <div className="Home_Welcome">
          <p>Daytime hotel experience at discounted rates</p>
          <h1 className="title">
            Your Daycation
            <br />
            starts today!
          </h1>
          <HomeSearch />
          <div className="Home_Search_Explore">
            <span
              onClick={() => {
                navigate("/explore", {
                  state: { exploreType: "rooms", outerInit: true },
                });
              }}
            >
              Explore all Rooms
            </span>
            <FaArrowRight
              style={{ color: "var(--secondary-color)", marginLeft: "0.5rem" }}
            />
          </div>
        </div>
        <div className="Home_Img">
          <img src={home_img} alt="" />
        </div>
      </div>
      {/* Second Part */}

      <div className="TrendingHotels_Container">
        <div className="TrendingHotels_Left">
          {hotelsLoading ? (
            <Loading />
          ) : (
            hotels.map((hotel, i) => {
              if (i > 3) return;
              return (
                <HotelCart
                  src={
                    process.env.REACT_APP_API + "/hotels/" + hotel?.coverImage
                  }
                  name={hotel?.name}
                  locationName={hotel?.location?.district?.name}
                  rating={hotel?.stars}
                  key={i}
                  Id={hotel._id}
                />
              );
            })
          )}
        </div>
        <div className="TrendingHotels_Right">
          <h1 className="title">Trending Hotels</h1>
          <p className="description">
            Discover a variety of trending hotels that everyone is enjoying!
          </p>
          <button
            className="btn"
            onClick={() => {
              navigate("/explore");
            }}
          >
            Explore all Hotels
          </button>
        </div>
      </div>
      <button
        className="btn exploreBtn"
        onClick={() => {
          navigate("/explore");
        }}
      >
        Explore all Hotels
      </button>
      <h1 className="Home_Why title">Why day Booking?</h1>
      <div className="Home_Features">
        <div className="cube cube1"></div>
        {/* Free cancelation */}
        <div className="Home_Feature">
          <BsClock style={{ color: "var(--secondary-color)" }} size={30} />
          <div className="Home_FeatureTitle">
            Free
            <br />
            Cancelation
          </div>
          <div className="description">
            Cancellation is free until the last minute before your check-in
          </div>
        </div>
        {/* Book now pay later */}
        <div className="Home_Feature">
          <FiCreditCard style={{ color: "var(--secondary-color)" }} size={30} />
          <div className="Home_FeatureTitle">
            Book Now
            <br />
            Pay Later
          </div>
          <div className="description">
            Book without a credit card! You pay directly when you check-in.
          </div>
        </div>
        {/* Enjoy Limitless Amenities */}
        <div className="Home_Feature">
          <img
            src={HotelSign}
            alt=""
            style={{ width: "35px", height: "35px" }}
          />
          <div className="Home_FeatureTitle">
            Enjoy Limitless
            <br />
            Amenities
          </div>
          <div className="description">
            Book a room for the day and make full use of all the hotel's
            amenities.
          </div>
        </div>
      </div>
      {/* Trending rooms */}

      <Carousel responsive={responsiveRoom} shouldResetAutoplay={false}>
        {roomLoading ? (
          <Loading />
        ) : (
          rooms?.map((r, i) => {
            return (
              <div className="trending_container">
                <div className="trending_sub">
                  <div className="trending_image">
                    <img
                      src={
                        process.env.REACT_APP_API +
                        "/rooms/" +
                        rooms[i]?.coverImage
                      }
                      alt=""
                    />
                  </div>
                  <div className="trending_images">
                    <img
                      src={
                        process.env.REACT_APP_API +
                        "/rooms/" +
                        rooms[i]?.images[0]
                      }
                      alt=""
                    />
                    <img
                      src={
                        process.env.REACT_APP_API +
                        "/rooms/" +
                        rooms[i]?.images[1]
                      }
                      alt=""
                    />
                    <img
                      src={
                        process.env.REACT_APP_API +
                        "/rooms/" +
                        rooms[i]?.images[2]
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div className="trending_desc">
                  <div>
                    <h1 className="title">
                      Trending Rooms
                      <br />
                      On Ooda
                    </h1>
                    <div className="trending_type">{rooms[i]?.type} Room</div>
                    <div className="trending_row">
                      <div className="trending_hotel">
                        {rooms[i] && (
                          <div className="trending_hotelname">
                            {rooms[i]?.hotel?.name},{" "}
                            {rooms[i]?.hotel?.location?.district?.name}
                          </div>
                        )}
                        <div className="trending_location">
                          <FaMapMarkerAlt
                            style={{ color: "var(--primary-color)" }}
                          />
                          {rooms[i] && (
                            <div>
                              {rooms[i]?.hotel?.location?.district?.name},
                              Lebanon
                            </div>
                          )}
                        </div>
                      </div>
                      <button
                        className="btn bookbtn"
                        onClick={() =>
                          navigate(`/hotel/${rooms[i].hotel?._id}`)
                        }
                      >
                        Book Now!
                      </button>
                    </div>
                  </div>
                  <div className="visited_container">
                    <div className="visited_row1">
                      {rooms[i]?.hotel?.number_of_visits !== 0 && (
                        <span>
                          {rooms[i]?.hotel?.number_of_visits}{" "}
                          {rooms[i]?.hotel?.number_of_visits === 1
                            ? "Person"
                            : "People"}{" "}
                          Visited This Place
                        </span>
                      )}
                      <div className="visited_heart">
                        <HeartComponent Room_Id={r._id} />
                      </div>
                    </div>
                    <div className="visited_row2">
                      <StarRatings
                        rating={rooms[i]?.hotel?.stars || 5}
                        starRatedColor={"#FFD700"}
                        numberOfStars={5}
                        name="rating"
                        starDimension={"20"}
                        starSpacing={"0"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </Carousel>

      {/* Testimonials */}

      {testimonials?.length > 0 && (
        <div className="testimonials_container">
          <div className="relative">
            <h1 className="title">What our Client says</h1>
            <div className="cube cube2"></div>
          </div>
          {testimonials?.length > 0 && (
            <Carousel
              responsive={responsive}
              className="testimonial_carousel"
              shouldResetAutoplay={false}
            >
              {loadingTestimonials ? (
                <Loading />
              ) : (
                testimonials?.map((t, i) => {
                  return (
                    <Testimonial
                      name={t.user?.firstName}
                      stars={t.stars}
                      avatar={
                        process.env.REACT_APP_API + "/users/" + t.user?.avatar
                      }
                      hotel={t?.hotel?.name}
                      location={t.hotel?.location?.district?.name}
                      text={t.comment}
                      amenities={t.hotel?.amenities}
                      key={i}
                    />
                  );
                })
              )}
            </Carousel>
          )}
        </div>
      )}

      {/* Email Subscriptions */}
      <EmailSubscription />
    </div>
  );
}

export default Home;
