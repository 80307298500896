import React from "react";
import { MdOutlineStar } from "react-icons/md";
import "./Reviews.scss";
function Reviews(props) {
  return (
    <div className="review_cont">
      <div className="header_cont">
        <div className="header_cont1">
          <div className="user_name">{props.name}</div>
          <i className="review_icon">
            {" "}
            <MdOutlineStar color="rgb(255, 215, 0)" />
          </i>
          <div className="number_rev">({props.rate})</div>
        </div>
        <div className="went_to">Went to: {props.hotel}</div>
      </div>
      <div className="review_text">"{props.review}"</div>
    </div>
  );
}

export default Reviews;
