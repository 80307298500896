/** @format */

import {
  FETCH_TESTIMONIALS_REQUEST,
  FETCH_TESTIMONIALS_SUCCESS,
  FETCH_TESTIMONIALS_FAIL,
  FETCH_HOTEL_TESTIMONIALS_REQUEST,
  FETCH_HOTEL_TESTIMONIALS_SUCCESS,
  FETCH_HOTEL_TESTIMONIALS_FAIL,
  CREATE_TESTIMONIAL_REQUEST,
  CREATE_TESTIMONIAL_SUCCESS,
  CREATE_TESTIMONIAL_FAIL,
  GET_USER_REVIEWS_REQUEST,
  GET_USER_REVIEWS_SUCCESS,
  GET_USER_REVIEWS_FAIL,
} from "./testimonialsTypes";

const initialState = {
  testimonials: [
    {
      _id: "",
      user: {
        _id: "",
        firstName: "",
        lastName: "",
        avatar: "",
      },
      hotel: {
        location: {
          map: {
            longitude: 0,
            latitude: 0,
          },
          district: {
            _id: "",
            name: "",
          },
        },
        _id: "",
        name: "",
      },
      stars: 5,
      comment: "",
      global: true,
    },
  ],
  hotelTestimonials: [
    {
      _id: "",
      user: {
        _id: "",
        firstName: "",
        lastName: "",
        avatar: "",
      },
      hotel: {
        location: {
          map: {
            longitude: 0,
            latitude: 0,
          },
          district: {
            _id: "",
            name: "",
          },
        },
        _id: "",
        name: "",
      },
      stars: 5,
      comment: "",
      global: true,
    },
  ],
  loadingTestimonials: false,
  error: "",
  loading: false,
  newTestimonialResponse: {},
  newTestimonailError: {},
};

export const testimonialsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TESTIMONIALS_REQUEST:
      return {
        ...state,
        loadingTestimonials: true,
      };
    case FETCH_TESTIMONIALS_SUCCESS:
      return {
        ...state,
        loadingTestimonials: false,
        testimonials: action.payload,
        error: "",
      };
    case FETCH_TESTIMONIALS_FAIL:
      return {
        ...state,
        loadingTestimonials: false,
        error: action.payload,
      };
    case FETCH_HOTEL_TESTIMONIALS_REQUEST:
      return {
        ...state,
        loadingTestimonials: true,
      };
    case FETCH_HOTEL_TESTIMONIALS_SUCCESS:
      return {
        ...state,
        loadingTestimonials: false,
        hotelTestimonials: action.payload,
        error: "",
      };
    case FETCH_HOTEL_TESTIMONIALS_FAIL:
      return {
        ...state,
        loadingTestimonials: false,
        error: action.payload,
      };

    case CREATE_TESTIMONIAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        newTestimonialResponse: action.payload,
      };
    case CREATE_TESTIMONIAL_FAIL:
      return {
        ...state,
        loading: false,
        newTestimonailError: action.payload,
      };
    case GET_USER_REVIEWS_REQUEST:
      return {
        ...state,
        loading: true,
        selfReviews: [],
      };
    case GET_USER_REVIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        selfReviews: action.payload,
      };
    case GET_USER_REVIEWS_FAIL:
      return {
        ...state,
        loading: false,
        selfReviews: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
