import {
  TRENDING_HOTELS_REQUEST,
  TRENDING_HOTELS_SUCCESS,
  TRENDING_HOTELS_FAIL,
  DISTRICT_HOTELS_REQUEST,
  DISTRICT_HOTELS_SUCCESS,
  DISTRICT_HOTELS_FAIL,
} from "./hotelsTypes";

const initialState = {
  hotels: [],
  discHotels:[],
  loading: false,
};

export const hotelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRENDING_HOTELS_REQUEST:
      return {
        ...state,
        hotels: [],
        loading: true,
      };
    case TRENDING_HOTELS_SUCCESS:
      return {
        ...state,
        hotels: action.payload,
        loading: false,
      };
    case TRENDING_HOTELS_FAIL:
      return {
        ...state,
        hotels: [],
        loading: false,
      };

      case DISTRICT_HOTELS_REQUEST:
      return {
        ...state,
        discHotels: [],
        loading: true,
      };
    case DISTRICT_HOTELS_SUCCESS:
      return {
        ...state,
        discHotels: action.payload,
        loading: false,
      };
    case DISTRICT_HOTELS_FAIL:
      return {
        ...state,
        discHotels: [],
        loading: false,
      };
    default:
      return state;
  }
};
