/** @format */

import React, { useEffect, useRef, useState } from "react";
import logo from "../../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import "./Header.scss";
import { FiMenu } from "react-icons/fi";
import { MdOutlineModeEditOutline, MdPadding } from "react-icons/md";
import { AiFillHeart } from "react-icons/ai";
import { VscBellDot } from "react-icons/vsc";
import bg from "../../../assets/images/street2.png";
import HotelBooking from "./HotelBooking";
import { socket } from "../../../App";
import { getBookingsForUser } from "../../../redux/bookings/bookingActions";
import {
  getNotifications,
  readAllNotifications,
  deleteAllNotifications,
} from "../../../redux/notifications/notificationsActions";
import { IoIosArrowDown } from "react-icons/io";
import { FaCheck } from "react-icons/fa";
import { update_info } from "../../../redux/user/userActions";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch, useSelector } from "react-redux";
import { getFavorites } from "../../../redux/wishlist/wishlistActions";
import HotelInWishlist from "../../HotelandRoom/HotelInWishlist";
import RoomInWishlist from "../../HotelandRoom/RoomInWishlist";
import { notificationsActions } from "../../../redux/notifications/notificationsReducer";
import { getReviewsForUser } from "../../../redux/testimonials/testimonialsActions";

function Header() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [currentBooking, setCurrentBooking] = useState(false);
  const [edit, setEdit] = useState(false);
  const { isAuthenticated, user } = useSelector((state) => state.userReducer);
  const bookingsReducer = useSelector((state) => state.bookingsReducer);
  const { wishlist } = useSelector((state) => state.wishlistReducer);
  const { notifications } = useSelector((state) => state.notificationsReducer);
  const [bookings, setBookings] = useState({ current: [], previous: [] });
  const ref = useRef();
  const dispatch = useDispatch();

  // The below state tend to handel the open\close state of the Header__Arrow
  const [openCurrentBookings, setOpenCurrentBookings] = useState(false);
  const [openPreviousBookings, setOpenPreviousBookings] = useState(false);
  const [openWishlist, setOpenWishlist] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  //Handeling name change
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [avatar, setAvatar] = useState(user.avatar);

  const handelInfoChange = () => {
    dispatch(update_info({ avatar, firstName, lastName }));
    setEdit(!edit);
  };

  const handleReadAll = () => {
    dispatch(readAllNotifications());
  };

  const handleClearAll = () => {
    dispatch(deleteAllNotifications());
  };

  var isSticky = false;
  const handleNavigation = (e) => {
    const window = e.currentTarget;
    var x = document.getElementsByClassName("header_container")[0].style;
    if (window.scrollY > 100 && !isSticky) {
      x.position = "fixed";
      x.backgroundColor = "white";
      x.justifyContent = "space-between";
      x.boxShadow = "0px 7px 15px -6px rgba(0, 0, 0, 0.5";
      x.margin = "0 3%";
      x.minWidth = "-webkit-fill-available";
      x.padding = "0 2%";
      isSticky = true;
    } else if (window.scrollY < 100 && isSticky) {
      x.position = "relative";
      x.backgroundColor = "transparent";
      x.boxShadow = "none";
      isSticky = false;
      x.margin = "1% 5%";
      x.padding = "0";
      x.top = 0;
    }
  };
  const handelImageChange = (e) => {
    setAvatar(e.target.files[0]);
  };

  useEffect(() => {
    window.addEventListener("scroll", (e) => handleNavigation(e));
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("notification", (notification) => {
        dispatch(notificationsActions.addNotification(notification));
      });
      return () => socket.off("notification");
    }
  }, []);
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getBookingsForUser());
      dispatch(getFavorites());
      dispatch(getNotifications());
      dispatch(getReviewsForUser());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const tempBookings = { current: [], previous: [] };
    bookingsReducer?.bookings?.forEach((booking) => {
      if (new Date(booking.date).toLocaleString() < new Date().toLocaleString())
        tempBookings.previous.push(booking);
      else tempBookings.current.push(booking);
    });
    setBookings(tempBookings);
  }, [bookingsReducer]);

  return (
    <div className="header_container globalContainer">
      <div className="header_img_container">
        <img
          src={logo}
          alt=""
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
      <div className="header_links">
        <Link to="/explore" className="header_link">
          <p>Hotels</p>
        </Link>
        <Link to="/about" className="header_link">
          <p className="header_link">About Us</p>
        </Link>
        <Link to="/support" className="header_link">
          <p className="header_link">Support</p>
        </Link>
        <button
          className="header_button"
          onClick={() => {
            isAuthenticated ? setOpen(!open) : navigate("/login");
          }}
        >
          {isAuthenticated ? "My Account" : "Sign In"}
        </button>
      </div>
      <div className="ham">
        <FiMenu
          color={"var(--secondary-color)"}
          size={30}
          onClick={() => {
            setOpen(true);
          }}
        />
      </div>
      {/* Menu on phone */}
      <div className={!open ? "Menu" : "MenuActive"}>
        <div className={!open ? "SubMenu" : "SubMenuActive"} ref={ref}>
          <div className="menu_head">
            <img
              src={bg}
              alt=""
              style={{
                height: "15rem",
                opacity: "0.5",
                width: "100%",
                objectFit: "cover",
              }}
            />
            <button
              onClick={() => {
                setOpen(false);
              }}
            >
              &times;
            </button>
          </div>
          {/*Menu on phone if loggedIn */}

          {isAuthenticated ? (
            <div className="menu_body">
              {/* first row -- user*/}
              <div className="user">
                <div className="img_container">
                  {edit && (
                    <Button
                      className="file_input"
                      variant="contained"
                      component="label"
                      color="primary"
                      borderRadius="50%"
                      onChange={(e) => handelImageChange(e)}
                    >
                      <AddIcon />
                      <input type="file" hidden />
                    </Button>
                  )}
                  <img
                    className={edit && "hide"}
                    src={
                      user?.avatar == null
                        ? process.env.REACT_APP_API + "/users/default.png"
                        : user.avatar.includes("googleusercontent")
                        ? user.avatar
                        : process.env.REACT_APP_API + "/users/" + user?.avatar
                    }
                    alt=""
                  />
                </div>
                <div className="user_info">
                  <div className="user_name">
                    {!edit ? (
                      <div className="user_info_header">
                        <div>{`${user?.firstName} ${user?.lastName}`}</div>
                        <MdOutlineModeEditOutline
                          color={"var(--text-color-two)"}
                          className="edit"
                          onClick={() => {
                            setEdit(!edit);
                          }}
                        />
                      </div>
                    ) : (
                      <div className="user_info_header">
                        <input
                          type="text"
                          placeholder="First Name"
                          value={firstName}
                          onChange={(e) => {
                            setFirstName(e.target.value.toString());
                          }}
                        />
                        <input
                          type="text"
                          placeholder="Last Name"
                          value={lastName}
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                        />
                        <FaCheck
                          className="fa-check-btn"
                          size={18}
                          onClick={handelInfoChange}
                        />
                      </div>
                    )}
                  </div>
                  <div className="user_email">{user?.email}</div>
                </div>
              </div>
              <div className="LgdOut_subMenu">
                <ul className="LoggedIn">
                  <li
                    className="LoggedOut_li"
                    onClick={() => navigate("/explore")}
                  >
                    Hotels
                  </li>
                  <li
                    className="LoggedOut_li"
                    onClick={() => navigate("/about")}
                  >
                    About Us
                  </li>
                  <li
                    className="LoggedOut_li"
                    onClick={() => navigate("/support")}
                  >
                    Support
                  </li>
                </ul>
                <hr className="LoggedOut_Header_hr" />
              </div>
              {/* second row -- current booking */}
              <div
                className="tab"
                onClick={() => {
                  setOpenCurrentBookings(!openCurrentBookings);
                  setCurrentBooking(!currentBooking);
                }}
              >
                Current Bookings ({bookings.current.length})
                <IoIosArrowDown
                  className={`Header__Arrow ${openCurrentBookings && "open"}`}
                />
              </div>
              <div
                className={`tab_content_container ${
                  openCurrentBookings && "clicked"
                }`}
              >
                {bookings.current.map((booking, i) => {
                  return (
                    <div
                      onClick={() => navigate("/confirmed/" + booking._id)}
                      style={{ cursor: "pointer" }}
                      key={i}
                    >
                      <HotelBooking
                        image={
                          process.env.REACT_APP_API +
                          "/rooms/" +
                          booking?.room?.coverImage
                        }
                        title={booking?.room?.hotel?.name}
                        location={
                          booking?.room?.hotel?.location?.district?.name
                        }
                        room={{
                          title: booking?.room?.type,
                          price: booking?.room?.price,
                        }}
                      />
                    </div>
                  );
                })}
              </div>

              <div
                className="tab"
                onClick={() => setOpenPreviousBookings(!openPreviousBookings)}
              >
                Previous Bookings ({bookings.previous.length})
                <IoIosArrowDown
                  className={`Header__Arrow ${openPreviousBookings && "open"}`}
                />
              </div>
              <div
                className={`tab_content_container ${
                  openPreviousBookings && "clicked"
                }`}
              >
                {bookings.previous.map((booking, i) => {
                  return (
                    <div
                      onClick={() => navigate("/confirmed/" + booking._id)}
                      style={{ cursor: "pointer" }}
                      key={i}
                    >
                      <HotelBooking
                        image={
                          process.env.REACT_APP_API +
                          "/rooms/" +
                          booking?.room?.coverImage
                        }
                        title={booking?.room?.hotel?.name}
                        location={
                          booking?.room?.hotel?.location?.district?.name
                        }
                        room={{
                          title: booking?.room?.type,
                          price: booking?.room?.price,
                          _id: booking?.room?.hotel?._id,
                        }}
                        previous={true}
                      />
                    </div>
                  );
                })}
              </div>
              <div
                className="tab"
                onClick={() => setOpenNotification(!openNotification)}
              >
                <VscBellDot color={"var(--primary-color)"} size={18} />
                <div>Notifications ({notifications.length})</div>
                <IoIosArrowDown
                  className={`Header__Arrow ${openNotification && "open"}`}
                />
              </div>
              <div
                className={`tab_content_container ${
                  openNotification && "clicked"
                }`}
              >
                <div className="row">
                  <span onClick={handleReadAll}>Mark all as Read</span>
                  <span onClick={handleClearAll}>Clear All</span>
                </div>
                {notifications.map((notification, i) => {
                  return (
                    <div className="notification_container" key={i}>
                      <div
                        className={`title ${
                          notification.read ? "" : "activeNotification"
                        }`}
                      >
                        {notification.title}
                      </div>
                      <div
                        className={`description ${
                          notification.read ? "" : "activeNotification"
                        }`}
                      >
                        {notification.description}
                      </div>
                      <div
                        className={`description date ${
                          notification.read ? "" : "activeNotification"
                        }`}
                      >
                        {new Date(notification.createdAt).toLocaleString()}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div
                className="tab"
                onClick={() => setOpenWishlist(!openWishlist)}
              >
                <AiFillHeart color={"var(--secondary-color)"} size={18} />
                <div>
                  Wishlist (
                  {wishlist?.wishlistRoom?.length +
                  wishlist?.wishlistHotel?.length
                    ? wishlist?.wishlistRoom?.length +
                      wishlist?.wishlistHotel?.length
                    : 0}
                  )
                </div>
                <IoIosArrowDown
                  className={`Header__Arrow ${openWishlist && "open"}`}
                />
              </div>
              <div
                className={`tab_content_container ${openWishlist && "clicked"}`}
              >
                <p style={{ textAlign: "center" }}>Hotel</p>
                {wishlist?.wishlistHotel?.map((HotelWish, i) => {
                  return (
                    <HotelInWishlist
                      src={HotelWish.coverImage}
                      title={HotelWish.name}
                      location={HotelWish?.location?.district?.name}
                      rating={HotelWish?.stars}
                      type="Hotel"
                      key={i}
                      HotelId={HotelWish._id}
                    />
                  );
                })}
                <p style={{ textAlign: "center" }}>Room</p>
                {wishlist?.wishlistRoom?.map((RoomWish, i) => {
                  return (
                    <RoomInWishlist type="Room" rooms={RoomWish} key={i} />
                  );
                })}
              </div>

              <div className="row">
                <div className="changePassword">Change Password</div>
                <div
                  className="logout"
                  onClick={() => {
                    localStorage.clear();
                    window.location.href = "/login";
                  }}
                >
                  Log out
                </div>
              </div>
            </div>
          ) : (
            <div className="LgdOut_menu_body">
              <div className="LgdOut_subMenu">
                <ul className="LoggedOut">
                  <li
                    className="LoggedOut_li"
                    onClick={() => navigate("/explore")}
                  >
                    Hotels
                  </li>
                  <li
                    className="LoggedOut_li"
                    onClick={() => navigate("/about")}
                  >
                    About Us
                  </li>
                  <li
                    className="LoggedOut_li"
                    onClick={() => navigate("/support")}
                  >
                    Support
                  </li>
                </ul>
                <hr className="LoggedOut_Header_hr" />
              </div>
              <div className="LgdOut_Btns">
                <button
                  className="LgdOut_login_btn"
                  onClick={() => navigate("/login")}
                >
                  Login
                </button>
                <button
                  className="LgdOut_logout_btn"
                  onClick={() => navigate("/signup")}
                >
                  Sign up
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Header;
