import React from "react";
import "./Gallery.scss";
import { useState } from "react";
const Gallery = ({ img1, img2, img3, showCarousel, setShowCarousel }) => {
  const HandelShowCarousel = () => {
    setShowCarousel(!showCarousel);
  };
  return (
    <div
      className="gallery"
      onClick={() => {
        setShowCarousel(!showCarousel);
      }}
    >
      <div className="left">
        <img src={img1} alt="" onClick={HandelShowCarousel} />
        <img src={img2} alt="" onClick={HandelShowCarousel} />
      </div>
      <div className="right">
        <img src={img3} alt="" onClick={HandelShowCarousel} />
      </div>
    </div>
  );
};

export default Gallery;
