export const RESET_FILTERS = "RESET_FILTERS";
export const RESET_FILTERED_DATA = "RESET_FILTERED_DATA";

export const FECTH_FILTERED_HOTELS_REQUEST = "FECTH_FILTERED_HOTELS_REQUEST";
export const FETCH_FILTERED_HOTELS_SUCCESS = "FETCH_FILTERED_HOTELS_SUCCESS";
export const FETCH_FILTERED_HOTELS_FAIL = "FETCH_FILTERED_HOTELS_FAIL";

export const FECTH_FILTERED_ROOMS_REQUEST = "FECTH_FILTERED_ROOMS_REQUEST";
export const FETCH_FILTERED_ROOMS_SUCCESS = "FETCH_FILTERED_ROOMS_SUCCESS";
export const FETCH_FILTERED_ROOMS_FAIL = "FETCH_FILTERED_ROOMS_FAIL";

export const UPDATE_TAGS = "UPDATE_TAGS";
export const UPDATE_DISTRICT = "UPDATE_DISTRICT";
export const UPDATE_AMENITIES = "UPDATE_AMENITIES";
export const UPDATE_PRICE = "UPDATE_PRICE";
export const APPLY_FILTERS = "APPLY_FILTERS";
export const UPDATE_SORT = "UPDATE_SORT";
