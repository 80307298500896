/** @format */

import React from "react";
import "./RoomPreview.scss";

function RoomPreview({ src, type, price }) {
  return (
    <div className="preview">
      <div>
        <img src={src} alt="" className="prev_img" />
        <div className="column">
          <div className="type">{type}</div>
        </div>
      </div>
      <div>
        <div className="price_col">
          <span>${price.toFixed(2)}</span>/day
        </div>
        <div className="changeRoom">Change room</div>
      </div>
    </div>
  );
}

export default RoomPreview;
