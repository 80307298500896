import React, { useState, useEffect } from "react";
import Select from "react-select";
import { FaSortAmountUpAlt, FaCrown } from "react-icons/fa";

function SortDropDowns({ sortChange, addMargin }) {
  const options = [
    { value: "name asc", display: "A-Z asc" },
    { value: "name desc", display: "A-Z desc" },
    { value: "price asc", display: "price asc" },
    { value: "price desc", display: "price desc" },
  ];
  return (
    <div
      className={
        addMargin ? "filter_sort_container" : "filter_sort_container margin_top"
      }
    >
      <i
        //tab inde makes the item focusable
        tabIndex={1}
        className="filter_icon_sort"
      >
        <FaSortAmountUpAlt className={"sort_icon"} size={"1.2rem"} />
      </i>
      <div className="filter_sort_list_container" tabIndex={5}>
        {options.map((option, i) => {
          return (
            <div
              onClick={() => {
                sortChange(option.value);
                document
                  .getElementsByClassName("filter_sort_list_container")[0]
                  .classList.add("hide");
                document
                  .getElementsByClassName("filter_sort_list_container")[1]
                  .classList.add("hide");
                setTimeout(() => {
                  document
                    .getElementsByClassName("filter_sort_list_container")[0]
                    .classList.remove("hide");
                  document
                    .getElementsByClassName("filter_sort_list_container")[1]
                    .classList.remove("hide");
                }, 200);
              }}
              tabIndex={i + 1}
              className={
                i == options.length - 1
                  ? "filter_sort_list_item last"
                  : "filter_sort_list_item"
              }
            >
              {option.display}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SortDropDowns;
