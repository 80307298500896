import React from "react";

import Explore_filter_price from "./Explore_filter_price.js";

export default ({ updatePrice, setResetPriceFunc }) => (
  <div className="filter__container">
    <div className="filter__row">
      <RangeFilter
        updatePrice={updatePrice}
        setResetPriceFunc={setResetPriceFunc}
      />
    </div>
  </div>
);

const RangeFilter = ({ updatePrice, setResetPriceFunc }) => (
  <div className="filter__item">
    <label htmlFor="price" className="label"></label>
    <div className="container price__container">
      <Explore_filter_price
        updatePrice={updatePrice}
        setResetPriceFunc={setResetPriceFunc}
      />
    </div>
  </div>
);
