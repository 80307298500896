export const FETCH_TESTIMONIALS_REQUEST = "FETCH_TESTIMONIALS_REQUEST";
export const FETCH_TESTIMONIALS_SUCCESS = "FETCH_TESTIMONIALS_SUCCESS";
export const FETCH_TESTIMONIALS_FAIL = "FETCH_TESTIMONIALS_FAIL";
export const FETCH_HOTEL_TESTIMONIALS_REQUEST =
  "FETCH_HOTEL_TESTIMONIALS_REQUEST";
export const FETCH_HOTEL_TESTIMONIALS_SUCCESS =
  "FETCH_HOTEL_TESTIMONIALS_SUCCESS";
export const FETCH_HOTEL_TESTIMONIALS_FAIL = "FETCH_HOTEL_TESTIMONIALS_FAIL";
export const CREATE_TESTIMONIAL_REQUEST = "CREATE_TESTIMONIAL_REQUEST";
export const CREATE_TESTIMONIAL_SUCCESS = "CREATE_TESTIMONIAL_SUCCESS";
export const CREATE_TESTIMONIAL_FAIL = "CREATE_TESTIMONIAL_FAIL";

export const GET_USER_REVIEWS_REQUEST = "GET_USER_REVIEWS_REQUEST";
export const GET_USER_REVIEWS_SUCCESS = "GET_USER_REVIEWS_SUCCESS";
export const GET_USER_REVIEWS_FAIL = "GET_USER_REVIEWS_FAIL";
