import {
  FETCH_TAGS_REQUEST,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_FAIL,
  FETCH_ACTIVE_TAGS_FAIL,
  FETCH_ACTIVE_TAGS_REQUEST,
  FETCH_ACTIVE_TAGS_SUCCESS,
} from "./tagsTypes";

const initialState = {
  tags: [],
  activeTags: [],
  loading: false,
  activeTagsError: "",
};

export const tagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TAGS_REQUEST:
      return {
        ...state,
        tags: [],
        loading: true,
      };
    case FETCH_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.payload,
        loading: false,
      };
    case FETCH_TAGS_FAIL:
      return {
        ...state,
        tags: [],
        loading: false,
      };
    case FETCH_ACTIVE_TAGS_REQUEST:
      return {
        ...state,
        activeTags: [],
        loading: true,
        activeTagsError: "",
      };
    case FETCH_ACTIVE_TAGS_SUCCESS:
      return {
        ...state,
        activeTags: action.payload,
        loading: false,
        activeTagsError: "",
      };
    case FETCH_ACTIVE_TAGS_FAIL:
      return {
        ...state,
        activeTags: [],
        loading: false,
        activeTagsError: action.payload,
      };
    default:
      return state;
  }
};
