import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Gallery from "./gallery/Gallery";
import GoogleMapReact from "google-map-react";
import "./Hotel.scss";
import HotelCart from "../Home/HotelCart";
import RoomCart from "../AssetComponents/RoomCart/RoomCart";
import RoomPreview from "./RoomPreview";
import Carousel from "react-multi-carousel";
import HotelDetails from "./HotelDetails";
import { getHotelDetails } from "../../redux/hotel/hotelActions";
import { getAmenitiesHotel } from "../../redux/amenities/amenitiesActions";
import { getHotelTestimonials } from "../../redux/testimonials/testimonialsActions";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { socket } from "../../App";
import Reviews from "../Reviews/Reviews";
import LocationPin from "../Explore/LocationPin";
import PureReactCarousel from "../AssetComponents/Carousel/PureReactCarousel";
import Loading from "../AssetComponents/Loading/Loading";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
const Hotel = () => {
  const [price, setPrice] = useState(0);
  const AnyReactComponent = ({ text }) => <div>{text}</div>;
  const [defaultProps, setDefaultProps] = useState({
    center: {
      lat: 33.8463,
      lng: 35.9019,
    },
    zoom: 8,
  });
  //Added by yousef
  const [showCarousel, setShowCarousel] = useState(false);
  const [showRoomCarousel, setShowRoomCarousel] = useState(false);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1200, min: 768 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  //End
  const [active, setActive] = useState(false);
  const [roomPrice, setRoomPrice] = useState(0);
  const [roomType, setRoomType] = useState("");
  const [roomImg, setRoomImg] = useState(null);
  const [roomId, setRoomId] = useState(0);
  const [roomDiscount, setRoomDiscount] = useState(0);
  const [bookingDate, setBookingDate] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [times, setTimes] = useState("");
  const [error, setError] = useState(false);
  const [roomUnavailable, setRoomUnavailable] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const hotelReducer = useSelector((state) => state.hotelReducer);
  const hotelsReducer = useSelector((state) => state.hotelsReducer);
  const amenitiesReducer = useSelector((state) => state.amenitiesReducer);
  const testimonialsReducer = useSelector((state) => state.testimonialsReducer);
  const { isAuthenticated } = useSelector((state) => state.userReducer);
  const [roomAmen, setRoomAmen] = useState([]);
  const [promPrice, setPromPrice] = useState(0);
  const [promAment, setPromAment] = useState([]);
  const [promIndex, setPromIndex] = useState(false);
  const [showMore, setShowMore] = useState([]);
  const [disabledDates, setDisabledDates] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      dispatch(getHotelDetails(id));
      dispatch(getAmenitiesHotel());
      dispatch(getHotelTestimonials(id));
    }
  }, [id]);

  const handleBook = () => {
    if (!isAuthenticated) navigate("/login");
    //set the room unavailable error to false
    setRoomUnavailable(false);
    //if the user did not choose a time slot
    if (times === "" || !bookingDate) {
      setError(true);
      return;
    } else {
      //else create a booking
      // only if "confirm book" was clicked
      setError(false);
      if (clicked) {
        createBooking();
      } else {
        setClicked(true);
      }
    }
  };

  function getHotelAm(id) {
    if (hotelReducer.hotel.amenities) {
      for (
        let index = 0;
        index < hotelReducer.hotel.amenities.length;
        index++
      ) {
        const element = hotelReducer.hotel.amenities[index];
        if (element._id === id) {
          return true;
        }
      }
    }
    return false;
  }
  const createBooking = () => {
    let testDate = new Date(bookingDate);
    testDate = new Date(
      testDate.getTime() + Math.abs(testDate.getTimezoneOffset() * 60000)
    ).toISOString();
    socket.emit(
      "booked",
      roomId,
      testDate,
      JSON.parse(times).startTime,
      JSON.parse(times).endTime
    );
  };
  useEffect(() => {
    let difference = [];
    hotelReducer.hotel?.rooms?.map((room, i) => {
      let price =
        room.price -
        (room.price * room.discount) / 100 -
        (roomPrice - (roomPrice * roomDiscount) / 100);
      price = price.toFixed(2);
      if ((promPrice >= price || promPrice == 0) && price > 0) {
        setPromPrice(price);
        difference = room.amenities.filter((x) => !roomAmen.includes(x));
        setPromIndex(i);
      }
    });
    setPromAment(difference);
  }, [active]);

  const clickedRoom = (i) => {
    let room = hotelReducer.hotel?.rooms[i];
    setRoomUnavailable(false);
    setActive(i);
    setRoomId(room?._id);
    setRoomType(room?.type);
    setRoomImg(room?.coverImage);
    setRoomPrice(room?.price);
    setRoomAmen(room?.amenities);
    setPromPrice(
      Math.round(
        hotelReducer.hotel?.rooms[0]?.price -
          (hotelReducer.hotel?.rooms[0]?.price *
            hotelReducer.hotel?.rooms[0]?.discount) /
            100 -
          (room?.price - (room?.price * room?.discount) / 100)
      )
    );
    setRoomDiscount(room?.discount);
    {
      showRoomCarousel && setShowRoomCarousel(!showRoomCarousel);
    }
    setDisabledDates(room?.disabledDates);
  };

  useEffect(() => {
    if (socket) {
      socket.on("booked", (booking) => {
        console.log(booking);
        if (booking?._id) {
          // go to confirmed page
          navigate("/confirmed/" + booking._id);
        } else {
          setRoomUnavailable(true);
        }
      });
      return function () {
        socket.off("booked");
      };
    }
  }, []);

  return (
    <div className="globalContainer">
      <div className="hotel_container">
        {showCarousel && (
          <PureReactCarousel
            carouselContent={hotelReducer.hotel?.images}
            showCarousel={showCarousel}
            setShowCarousel={setShowCarousel}
            type={"Hotel"}
          />
        )}
        {hotelReducer.loading ? (
          <Loading />
        ) : (
          hotelReducer.hotel?.images?.length > 0 && (
            <Gallery
              img3={
                process.env.REACT_APP_API +
                "/hotels/" +
                hotelReducer.hotel?.images[0]
              }
              img2={
                process.env.REACT_APP_API +
                "/hotels/" +
                hotelReducer.hotel?.images[1]
              }
              img1={
                process.env.REACT_APP_API +
                "/hotels/" +
                hotelReducer.hotel?.images[2]
              }
              showCarousel={showCarousel}
              setShowCarousel={setShowCarousel}
            />
          )
        )}

        {/* Hotel details */}
        <div className="container_row">
          {/* on mobile, the layout changes so i repeated this part */}
          <div className="hotel_details_mobile">
            <HotelDetails
              name={hotelReducer.hotel?.name}
              logo={
                process.env.REACT_APP_API +
                "/hotels/" +
                hotelReducer.hotel?.logo
              }
              rating={hotelReducer.hotel?.stars}
              nbReviews={hotelReducer.hotel?.testimonials?.length}
              location={
                "Lebanon, " +
                hotelReducer.hotel?.location?.district?.name +
                " District"
              }
              description={hotelReducer.hotel?.description}
              Id={hotelReducer.hotel?._id}
            />
          </div>
          {/* left part */}
          <div className="leftpart">
            <div className="hotel_details_cont">
              {hotelReducer.loading ? (
                <Loading />
              ) : (
                <HotelDetails
                  name={hotelReducer.hotel?.name}
                  logo={
                    process.env.REACT_APP_API +
                    "/hotels/" +
                    hotelReducer.hotel?.logo
                  }
                  rating={hotelReducer.hotel?.stars}
                  nbReviews={hotelReducer.hotel?.testimonials?.length}
                  location={
                    "Lebanon, " +
                    hotelReducer.hotel?.location?.district?.name +
                    " District"
                  }
                  description={hotelReducer.hotel?.description}
                  Id={hotelReducer.hotel?._id}
                />
              )}
            </div>
            <div className="select_room title">Select your room</div>
            <div className="room_carts">
              {hotelReducer.loading ? (
                <Loading />
              ) : (
                hotelReducer.hotel?.rooms?.map((room, i) => {
                  return (
                    <RoomCart
                      img={
                        process.env.REACT_APP_API + "/rooms/" + room.coverImage
                      }
                      heart={true}
                      border={active === i ? true : false}
                      price={room.price}
                      roomType={room.type}
                      RoomId={room?._id}
                      amenities={room?.amenities}
                      onClick={() => clickedRoom(i, room)}
                      description={room.description}
                      key={i}
                      discount={room.discount}
                      available={room.availability}
                      showRoomCarousel={showRoomCarousel}
                      setShowRoomCarousel={setShowRoomCarousel}
                      setRoomId={setRoomId}
                    />
                  );
                })
              )}
              {showRoomCarousel && (
                <PureReactCarousel
                  carouselContent={
                    hotelReducer.hotel?.rooms.filter((r) => r._id === roomId)[0]
                      ?.images
                  }
                  showCarousel={showRoomCarousel}
                  setShowCarousel={setShowRoomCarousel}
                  type={"Room"}
                />
              )}
            </div>
            <div className="select_room title">Amenities at the Hotel</div>

            <div className="amenities">
              {amenitiesReducer.loading ? (
                <Loading />
              ) : (
                amenitiesReducer.amenities_hotel.map((amenity, i) => {
                  return (
                    <div
                      className={
                        getHotelAm(amenity._id)
                          ? "hotel_amenities available"
                          : "hotel_amenities not_available"
                      }
                      key={i}
                    >
                      <div
                        className={
                          getHotelAm(amenity._id)
                            ? "bullet_active"
                            : "bullet_not_active"
                        }
                      />
                      <img
                        className={
                          getHotelAm(amenity._id)
                            ? "amenity_icon"
                            : "amenity_icon_not_ac"
                        }
                        src={
                          process.env.REACT_APP_API +
                          "/amenities/" +
                          amenity.icon
                        }
                      />
                      {amenity.name}
                    </div>
                  );
                })
              )}
            </div>
            {active !== false && promPrice > 0 && (
              <div className="promotion">
                <div className="title">Promotion!</div>

                <p>
                  With only an additional ${promPrice} you can have access to{" "}
                  <br /> A room with the following amenities:{" "}
                  {promAment.map((amenity, i) => {
                    return i !== 0 ? ", " + amenity.name : "" + amenity.name;
                  })}
                </p>
                <button onClick={() => clickedRoom(promIndex)}>See Room</button>
              </div>
            )}
            <div className="map_cont">
              <div style={{ height: "100%", width: "90%" }}>
                <GoogleMapReact
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                  bootstrapURLKeys={{
                    key: "AIzaSyDzgiQR34otMk9omwkAhr4xOsAOc69ZVnw",
                  }}
                >
                  {hotelReducer.hotel?.rooms?.map((room) => {
                    if (price === 0 || room.price < price) {
                      setPrice(room.price);
                    }
                  })}

                  <LocationPin
                    lat={hotelReducer.hotel?.location?.map.longitude}
                    lng={hotelReducer.hotel?.location?.map.latitude}
                    text={price}
                  />
                </GoogleMapReact>
              </div>
            </div>
          </div>

          {/* Right part */}
          {active !== false && (
            <div className="rightpart">
              <div className="subcontainer">
                {error && (
                  <div className="error">Please pick a date and time</div>
                )}
                {roomUnavailable && (
                  <div className="error">
                    Sorry, this Room is Unavailable at the specified date and
                    time!
                  </div>
                )}
                <div className="row">
                  <div className="choose_date">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        minDate={new Date()}
                        label="Date"
                        inputFormat="MM/dd/yyyy"
                        value={bookingDate}
                        onChange={(value) => {
                          setBookingDate(value);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        shouldDisableDate={(date) => {
                          console.log("augh");
                          let flag = 0;
                          disabledDates.forEach((dd) => {
                            if (
                              new Date(dd).setHours(0, 0, 0, 0) ===
                              date.setHours(0, 0, 0, 0)
                            ) {
                              flag = 1;
                            }
                          });
                          return flag === 1 ? true : false;
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                  <select
                    className="choose_time"
                    defaultValue={""}
                    onChange={(e) => {
                      setTimes(e.target.value);
                    }}
                  >
                    <option value="">Choose a time</option>
                    {hotelReducer?.hotel?.availabilities?.map((av, i) => {
                      return (
                        <option value={JSON.stringify(av)} key={i}>
                          {av.startTime} -&gt; {av.endTime}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {/* room preview */}
                <RoomPreview
                  src={process.env.REACT_APP_API + "/rooms/" + roomImg}
                  type={roomType}
                  price={roomPrice}
                />
                <div className="price_details">
                  <div className="row">
                    <div>1 Day</div>
                    <div>${roomPrice}</div>
                  </div>
                  <div className="row">
                    <div>Discount</div>
                    <div>{roomDiscount}%</div>
                  </div>
                </div>
                <div className="totalPrice">
                  <div className="row">
                    <div>Total Payment</div>

                    <div>
                      $
                      {(roomPrice - roomPrice * (roomDiscount / 100)).toFixed(
                        2
                      )}
                    </div>
                  </div>
                </div>
                {hotelReducer.hotel?.isActive ? (
                  <>
                    <button
                      className={
                        clicked ? "book_btn confirm_booking" : "book_btn"
                      }
                      onClick={handleBook}
                    >
                      {clicked ? "Confirm Booking" : "Book"}
                    </button>
                    <div className="notes">
                      <span>Note:</span>
                      <span>You'll pay at the hotel.</span>
                    </div>
                  </>
                ) : (
                  <button className="unavialable_book">
                    Unavailable to Book
                  </button>
                )}
              </div>
            </div>
          )}
        </div>

        {hotelsReducer.discHotels?.length > 0 && (
          <div className="select_room title">More Suggestions</div>
        )}
        <div className="suggestions">
          <Carousel responsive={responsive}>
            {hotelsReducer.loading ? (
              <Loading />
            ) : (
              hotelsReducer.discHotels?.map((hotel, i) => {
                return (
                  <HotelCart
                    src={
                      process.env.REACT_APP_API + "/hotels/" + hotel.coverImage
                    }
                    name={hotel.name}
                    locationName={hotel.location.district.name}
                    rating={hotel.stars}
                    Id={hotel._id}
                    key={i}
                  />
                );
              })
            )}
          </Carousel>
        </div>
        {testimonialsReducer.hotelTestimonials.length > 0 && (
          <>
            <div className="select_room title">Reviews</div>
            <div className="reviews">
              <Carousel responsive={responsive}>
                {testimonialsReducer.loadingTestimonials ? (
                  <Loading />
                ) : (
                  testimonialsReducer.hotelTestimonials?.map((review, i) => {
                    return (
                      <Reviews
                        name={
                          review.user.firstName + " " + review.user.lastName
                        }
                        rate={review.stars}
                        hotel={
                          review?.hotel?.name +
                          ", " +
                          review.hotel.location.district?.name +
                          " Lebanon"
                        }
                        review={review.comment}
                        key={i}
                      />
                    );
                  })
                )}
              </Carousel>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Hotel;
