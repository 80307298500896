import {
  FETCH_ROOMS_REQUEST,
  FETCH_ROOMS_SUCCESS,
  FETCH_ROOMS_FAIL,
} from "./roomsTypes";
import axios from "axios";

export const getTrendingRooms = () => async (dispatch) => {
  dispatch({
    type: FETCH_ROOMS_REQUEST,
  });
  try {
    const res = await axios.get(
      process.env.REACT_APP_API + "/room?isGlobal=true"
    );
    dispatch({
      type: FETCH_ROOMS_SUCCESS,
      payload: res.data.rooms,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: FETCH_ROOMS_FAIL,
    });
  }
};
