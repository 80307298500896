import axios from "axios";
import {
  FECTH_DISTRICTS_REQUEST,
  FETCH_DISTRICTS_FAIL,
  FETCH_DISTRICTS_SUCCESS,
} from "./districtsTypes";

export const getDistricts = () => async (dispatch) => {
  dispatch({
    type: FECTH_DISTRICTS_REQUEST,
  });
  try {
    const response = await axios.get(process.env.REACT_APP_API + "/district/");
    dispatch({
      type: FETCH_DISTRICTS_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: FETCH_DISTRICTS_FAIL,
      payload: e.response ? e.response.message : "error occured",
    });
  }
};
