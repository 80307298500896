/** @format */

import React, { useEffect, useState } from "react";
import "./RoomCart/RoomCart.scss";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  addHotelFavorites,
  addRoomFavorites,
  rmvRoomFavorites,
  removeHotelFavorites,
} from "../../redux/wishlist/wishlistActions";
import { useDispatch } from "react-redux";

function HeartComponent({ Hotel_Id, Room_Id }) {
  let InitialState = false;
  const wishlistedRooms = useSelector(
    (state) => state.wishlistReducer.wishlist.wishlistRoom
  );
  const wishlistedHotels = useSelector(
    (state) => state.wishlistReducer.wishlist.wishlistHotel
  );
  const dispatch = useDispatch();

  if (Hotel_Id) {
    wishlistedHotels.find((hotel) => {
      if (hotel._id === Hotel_Id) {
        InitialState = true;
      }
    });
  } else if (Room_Id) {
    wishlistedRooms.find((room) => {
      if (room._id === Room_Id) {
        InitialState = true;
      }
    });
  }
  const [heartClicked, setHeartClicked] = useState(InitialState);

  useEffect(() => {
    setHeartClicked(InitialState);
  }, [InitialState]);

  const changeHeartStatus = (newStatus) => {
    console.log(Room_Id);
    if (Hotel_Id && newStatus) {
      dispatch(addHotelFavorites(Hotel_Id));
    } else if (Hotel_Id && !newStatus) {
      dispatch(removeHotelFavorites(Hotel_Id));
    }

    if (Room_Id && newStatus) {
      dispatch(addRoomFavorites(Room_Id));
    } else if (Room_Id && !newStatus) {
      dispatch(rmvRoomFavorites(Room_Id));
    }
    setHeartClicked(newStatus);
  };

  return (
    <div className="HotelCart_HeartContainer">
      <i onClick={() => changeHeartStatus(!heartClicked)}>
        {heartClicked ? (
          <FaHeart size={"1.1rem"} />
        ) : (
          <FaRegHeart size={"1.1rem"} />
        )}
      </i>
    </div>
  );
}

export default HeartComponent;
