import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Pagination.scss";
import {
  HiChevronDoubleRight,
  HiChevronDoubleLeft,
  HiChevronRight,
  HiChevronLeft,
} from "react-icons/hi";
function PaginationComp(props) {
  const { page, totalPages, onClick } = props;

  return (
    <div className="explore_pagination">
      <div
        className={
          page !== 1
            ? "explore_pagination_click"
            : "explore_pagination_click_disabled"
        }
        onClick={() => {
          onClick(1);
        }}
      >
        <HiChevronDoubleLeft />
      </div>

      <div
        className={
          page !== 1
            ? "explore_pagination_click"
            : "explore_pagination_click_disabled"
        }
        onClick={() => {
          onClick(Number(page) - 1);
        }}
      >
        <HiChevronLeft size={"1.1rem"} />
      </div>

      <div className="current_page">{page}</div>

      <div
        className={
          page < totalPages
            ? "explore_pagination_click"
            : "explore_pagination_click_disabled"
        }
        onClick={() => {
          onClick(Number(page) + 1);
        }}
      >
        <HiChevronRight size={"1.1rem"} />
      </div>

      <div
        className={
          page < totalPages
            ? "explore_pagination_click"
            : "explore_pagination_click_disabled"
        }
        onClick={() => {
          onClick(totalPages);
          window.scrollTo(0, 0); // Corrected the function name to scrollTo
        }}
      >
        <HiChevronDoubleRight />
      </div>

      {/* Display the page number at the bottom */}
      {/* <div className="current_page_bottom">{page}</div> */}
    </div>
  );
}
export default PaginationComp;
