import React from "react";
import { FaMapMarkerAlt } from "react-icons/fa";

function LocationPin({ text, onClick }) {
  return (
    <div className="pin">
      <i className="pin-icon" onClick={onClick}>
        <FaMapMarkerAlt
          style={{ transform: `translate(-50%, -100%)` }}
          size={"2rem"}
        />{" "}
        <p className="pin-text" style={{ transform: `translate(-50%, -100%)` }}>
          {text}$
        </p>
      </i>
    </div>
  );
}

export default LocationPin;
