/** @format */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bookings: [],
  loading: false,
  error: "",
};

const bookingsSlice = createSlice({
  initialState,
  name: "bookings",
  reducers: {
    fetchBookingsRequest(state) {
      state.loading = true;
    },
    fetchBookingsSuccess(state, action) {
      state.loading = false;
      state.bookings = action.payload;
    },
    fetchBookingsFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const bookingsActions = bookingsSlice.actions;
export default bookingsSlice;
