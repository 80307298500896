import React from "react";
import GoogleMapReact from "google-map-react";
import LocationPin from "./LocationPin";

function Map({ hotel }) {
  const defaultProps = {
    center: {
      lat: 33.8463,
      lng: 35.9019,
    },
    zoom: 10,
  };

  const navigate = (id) => {
    window.location.href = "hotel/" + id;
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <GoogleMapReact
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        bootstrapURLKeys={{ key: "AIzaSyDzgiQR34otMk9omwkAhr4xOsAOc69ZVnw" }}
      >
        {hotel?.map((hotel) => {
          let price = hotel.rooms[0]?.price;
          {
            hotel.rooms?.map((room) => {
              if (room.price < price) {
                price = room.price;
              }
            });
          }
          return (
            <LocationPin
              lat={hotel.location.map.longitude}
              lng={hotel.location.map.latitude}
              text={price}
              onClick={() => navigate(hotel._id)}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
}

export default Map;
