import {
  FECTH_HOTEL_REQUEST,
  FETCH_HOTEL_SUCCESS,
  FETCH_HOTEL_FAIL,
} from "./hotelTypes";

const initialState = {
  hotel: {},
  loading: false,
};

export const hotelReducer = (state = initialState, action) => {
  switch (action.type) {
    case FECTH_HOTEL_REQUEST:
      return {
        ...state,
        hotel: {},
        loading: true,
      };
    case FETCH_HOTEL_SUCCESS:
      return {
        ...state,
        hotel: action.payload,
        loading: false,
      };
    case FETCH_HOTEL_FAIL:
      return {
        ...state,
        hotel: {},
        loading: false,
      };

    default:
      return state;
  }
};
