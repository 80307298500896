import React from "react";
import InputRange from "react-input-range";
import "./explore_filter_price.scss";
import "react-input-range/lib/css/index.css";
import "./range.scss";

import Log from "./log";

export default class Range extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: { min: 0, max: 100 }, pricemin: 0, pricemax: 0 };
    this.logSlider = new Log({
      minpos: 0,
      maxpos: 100,
      minval: 0,
      maxval: 2000,
    });
    this.onChange = this.onChange.bind(this);
    this.formatLabel = this.formatLabel.bind(this);
    this.changeSlider = this.changeSlider.bind(this);
    this.commitChange = this.commitChange.bind(this);
    this.props.setResetPriceFunc(() => this.onChange);
  }

  calcPos(pos) {
    if (pos === 0) return 0;
    const val = this.logSlider.value(pos);
    return val;
  }

  onChange(value1) {
    this.setState({ value: value1 });
    this.setState({ pricemin: value1.min });
    this.setState({ pricemax: value1.max });
    var min = this.calcPos(value1.min);
    var max = this.calcPos(value1.max);
    document.getElementById("input1").value = this.calcPos(value1.min);
    document.getElementById("input2").value = this.calcPos(value1.max);
  }

  commitChange(value) {
    var min = this.calcPos(value.min);
    var max = this.calcPos(value.max);
    this.props.updatePrice(min, max);
  }

  formatLabel(value) {
    return `${this.calcPos(value)}`;
  }

  changeSlider() {
    const min = document.getElementById("input1").value;
    const max = document.getElementById("input2").value;
    this.setState({
      value: {
        min: min / this.logSlider.scale,
        max: max / this.logSlider.scale,
      },
    });
    this.props.updatePrice(min, max);
  }

  render() {
    return (
      <div className="budget_main_container">
        <div className="explore_input1">
          <div style={{ marginBottom: "4px", marginRight: "5%" }}>Min</div>
          <input
            id="input1"
            type="number"
            step={100}
            min={0}
            max={2000}
            defaultValue={0}
            onChange={this.changeSlider}
          ></input>
        </div>
        <RangeInput
          id="range"
          value={this.state.value}
          onChange={this.onChange}
          formatLabel={this.formatLabel}
          commitChange={this.commitChange}
        />
        <div className="explore_input2">
          <input
            id="input2"
            type="number"
            step={100}
            min={0}
            max={2000}
            defaultValue={2000}
            onChange={this.changeSlider}
          ></input>
          <div style={{ marginBottom: "4px", marginLeft: "5%" }}>Max</div>
        </div>
      </div>
    );
  }
}
const RangeInput = ({ value, onChange, commitChange, formatLabel }) => (
  <InputRange
    step={5}
    formatLabel={formatLabel}
    maxValue={100}
    minValue={0}
    value={value}
    onChange={onChange}
    onChangeComplete={commitChange}
  />
);
