import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Map from "../Explore/Map";
import home_img from "../../assets/images/home_img.webp";
import luxuryspa from "../../assets/images/luxuryspa.webp";
import "./About.scss";

function About() {
  const [hotels, setHotels] = useState({});
  useEffect(async () => {
    const response = await axios.get(process.env.REACT_APP_API + "/hotel/all");
    setHotels(response.data.hotels);
  }, []);

  return (
    <div className="Support_Container globalContainer">
      <div className="About_bg">
        {/* First Part */}

        <div className="Support_Welcome">
          <h1 className="title">About Us</h1>
          <p className="paragraph">
            Hotels have traditionally provided overnight lodging to provide a
            mass vacation experience that everyone knows and enjoys. With
            changing times come changing needs, and that’s where OODA comes in.
            <br />
            We’re here to let people enjoy the luxury of having an adventure and
            returning to their comfy beds come day’s end.
            <br />
            We empower hotels with the ability to cater to daycationers without
            sweating a finger, focusing on what they do best: hospitality.
            <br />
            In a dynamic and unpredictable world, we know for a fact that
            freedom is a rare commodity often taken for granted. Yet a simple
            sunset drive may be enough to replenish the soul, or a genuine
            family moment in a new destination may be the new tradition that
            ages like wine.
            <br />
            OODA was created for moments like these. Sure, there’s always the
            last-minute wardrobe change, on-the-go meeetings, and dozens of
            other use cases we’ve seen, but st the heart of every booking is an
            intention to change, to live a little.
          </p>
        </div>
        <div className="map_parent">
          {hotels.length > 0 && <Map hotel={hotels} />}
        </div>
      </div>
    </div>
  );
}

export default About;
