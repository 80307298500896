import axios from "axios";
import {
  FECTH_AMENITIES_REQUEST,
  FETCH_AMENITIES_FAIL,
  FETCH_AMENITIES_SUCCESS,
  FECTH_AMENITIES_HOTEL_REQUEST,
  FETCH_AMENITIES_HOTEL_FAIL,
  FETCH_AMENITIES_HOTEL_SUCCESS
} from "./amenitiesTypes";


//?belongToHotel=true to do
export const getAmenities = () => async (dispatch) => {
  dispatch({
    type: FECTH_AMENITIES_REQUEST,
  });
  try {
    const response = await axios.get(process.env.REACT_APP_API + "/amenity");
    dispatch({
      type: FETCH_AMENITIES_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: FETCH_AMENITIES_FAIL,
      payload: e.response ? e.response.message : "error occured",
    });
  }
};

export const getAmenitiesHotel = () => async (dispatch) => {
  dispatch({
    type:   FECTH_AMENITIES_HOTEL_REQUEST,
  });
  try {
    const response = await axios.get(process.env.REACT_APP_API + "/amenity?belongToHotel=true");
    dispatch({
      type:   FETCH_AMENITIES_HOTEL_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: FETCH_AMENITIES_HOTEL_FAIL,
      payload: e.response ? e.response.message : "error occured",
    });
  }
};


