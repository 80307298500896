/** @format */

import React, { useState } from "react";
import { FaMapMarkerAlt, FaRegHeart, FaHeart } from "react-icons/fa";
import StarRatings from "react-star-ratings/build/star-ratings";
import HeartComponent from "../AssetComponents/HeartComponent";

function HotelDetails({
  name,
  logo,
  rating,
  nbReviews,
  location,
  description,
  Id,
}) {
  return (
    <>
      <div className="details_header">
        <img className="img" src={logo} alt="" />
        <div>
          <div className="row1">
            <h1>{name}</h1>
            <HeartComponent Hotel_Id={Id} />
          </div>
          <div className="row2">
            {rating && (
              <StarRatings
                rating={rating}
                starRatedColor={"#FFCF00"}
                numberOfStars={Math.floor(rating)}
                name="rating"
                starDimension={"15"}
                starSpacing={"0"}
              />
            )}
            <p className="nbreviews">({nbReviews} Reviews)</p>
            <div className="location_container">
              <FaMapMarkerAlt
                style={{ color: "var(--secondary-color)" }}
                size={10}
                className="marker"
              />
              <div className="location">{location}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="details">
        <div className="title">Details</div>
        <p>{description}</p>
      </div>
    </>
  );
}

export default HotelDetails;
