import axios from "axios";

import {
  APPLY_FILTERS,
  FECTH_FILTERED_HOTELS_REQUEST,
  FECTH_FILTERED_ROOMS_REQUEST,
  FETCH_FILTERED_HOTELS_FAIL,
  FETCH_FILTERED_HOTELS_SUCCESS,
  FETCH_FILTERED_ROOMS_FAIL,
  FETCH_FILTERED_ROOMS_SUCCESS,
  RESET_FILTERED_DATA,
  RESET_FILTERS,
  UPDATE_SORT,
  UPDATE_AMENITIES,
  UPDATE_DISTRICT,
  UPDATE_PRICE,
  UPDATE_TAGS,
} from "./filtersTypes";
const hotelsUrl = process.env.REACT_APP_API + "/hotel?";
const roomsUrl = process.env.REACT_APP_API + "/room?";
export const getHotels =
  (page, didFilterChange = false, filters) =>
  async (dispatch) => {
    try {
      dispatch({ type: FECTH_FILTERED_HOTELS_REQUEST });

      const url = _injectFiltersToUrl(hotelsUrl, page, filters);
      const res = await axios.get(url);
      dispatch({
        type: FETCH_FILTERED_HOTELS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: FETCH_FILTERED_HOTELS_FAIL,
      });
    }
  };

export const getRooms =
  (page, didFilterChange = false, filters) =>
  async (dispatch) => {
    try {
      dispatch({ type: FECTH_FILTERED_ROOMS_REQUEST });
      const url = _injectFiltersToUrl(roomsUrl, page, filters);
      const res = await axios.get(url);
      dispatch({
        type: FETCH_FILTERED_ROOMS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: FETCH_FILTERED_ROOMS_FAIL,
      });
    }
  };

export const updateFilters = (type, data) => async (dispatch) => {
  if (type === "tags") {
    dispatch({
      type: UPDATE_TAGS,
      payload: data.tags,
    });
  }
  if (type === "district") {
    dispatch({
      type: UPDATE_DISTRICT,
      payload: data.district,
    });
  }
  if (type === "price") {
    dispatch({
      type: UPDATE_PRICE,
      payload: {
        minPrice: data.minPrice,
        maxPrice: data.maxPrice,
      },
    });
  }
  if (type === "amenities") {
    dispatch({
      type: UPDATE_AMENITIES,
      payload: data.amenities,
    });
  }
  if (type === "sort") {
    dispatch({
      type: UPDATE_SORT,
      payload: data.sort,
    });
  }
};

export const applyfilters = (filters) => async (dispatch) => {
  dispatch({ type: APPLY_FILTERS, payload: filters });
};
export const resetFilters = () => (dispatch) => {
  dispatch({ type: RESET_FILTERED_DATA });
};

const _injectFiltersToUrl = (url, page, filters) => {
  const amentiesChunk = filters.amenities
    .map((amenity) => "amenities[]=" + amenity)
    .join("&");
  const tagsChunk = filters.tags.map((tag) => "tags[]=" + tag).join("&");
  var url = `${url}page=${page}&${tagsChunk}&${amentiesChunk}&district=${filters.district}&minPrice=${filters.minPrice}&maxPrice=${filters.maxPrice}&sort=${filters.sort}`;

  //replace && and &&& with &
  url = url.replace(/&{2,3}/g, "&");
  return url;
};
