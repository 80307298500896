import React, { useEffect, useRef, useState } from "react";
import "./Confirmed.scss";
import confirm from "../../assets/images/confirm.png";
import { ReactComponent as Line } from "./line.svg";
import { ReactComponent as Circle } from "./circle.svg";
import { ReactComponent as Hotel } from "./Hotel.svg";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { socket } from "../../App";
import ConfirmRoomPreview from "./ConfirmRoomPreview";

function Confirmed() {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const navigate = useNavigate();
  let { id } = useParams();
  const [currentBooking, setBooking] = useState({});
  const [data, setData] = useState([]);
  var flag = 0;
  useEffect(async () => {
    try {
      setData(await axios.get(process.env.REACT_APP_API + "/booking/user"));
    } catch (error) {
      window.location.href = "/";
    }
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (data && data.data && data.data.length > 0) {
      data.data.forEach((booking) => {
        if (booking._id === id) {
          console.log(booking);
          setBooking(booking);
          flag = flag + 1;
          return;
        }
      });

      if (flag == 0) {
        window.location.href = "/";
      }
    }
  }, [data]);

  const checkDate = new Date(currentBooking.date);
  console.log(currentBooking.date);
  console.log(checkDate);
  const month = checkDate.toLocaleString("default", { month: "long" });
  const day = checkDate.getDate();
  const cancelBooking = async () => {
    try {
      await axios.delete(process.env.REACT_APP_API + "/booking/" + id);
      let token = localStorage.getItem("token");
      socket.emit(
        "booking canceled",
        token,
        currentBooking.startTime,
        currentBooking.endTime,
        new Date(currentBooking.date).toLocaleDateString()
      );
    } catch (error) {
      console.log(error);
      return;
    }

    window.location.href = "/";
  };
  return (
    <div className="globalContainer">
      <div className="confirm_container">
        {open && (
          <div className="cancel_modal">
            <div className="cancel_cont" ref={ref}>
              <div className="modal_row">
                <Hotel />
                <span>Cancel Booking?</span>
              </div>
              <div className="modal_body">
                <div className="title">
                  Hello {currentBooking.user?.firstName}!
                </div>
                <div className="desc">
                  Just making sure that you want to proceed with your
                  cancelation
                </div>
              </div>
              <button className="btn" onClick={cancelBooking}>
                Cancel Booking
              </button>
            </div>
          </div>
        )}
        {/* Left Part */}
        <div className="confirm_left">
          <div className="confirm_status">
            <img src={confirm} alt="" />
            <div className="confirm_status_text">
              <h1>Booking Confirmed</h1>
              <span>
                We have booked your spot for the selected data and the selected
                time slot. Please arrive at the hotel in due date and show your
                booking information at the counter
              </span>
            </div>
          </div>
          <span className="mobile_text">
            We have booked your spot for the selected data and the selected time
            slot. Please arrive at the hotel in due date and show your booking
            information at the counter
          </span>
          <div className="booking_steps">
            <div className="steps_path">
              <Circle />
              <Line />
              <Circle />
              <Line />
              <Circle />
              <Line />
              <Circle />
            </div>
            <div className="steps_details">
              <span>
                You arrive to {currentBooking.room?.hotel?.name} on the{" "}
                <b>
                  {day}
                  {day == 1
                    ? "st"
                    : day == 2
                    ? "nd"
                    : day == 3
                    ? "rd"
                    : "th"}{" "}
                  {month}
                </b>
              </span>
              <span>
                You check in at <b>{currentBooking.startTime}</b>
              </span>
              <span>
                You pay at <b>the hotel</b>
              </span>
              <span>
                You check out at <b>{currentBooking.endTime}</b> (Same day)
              </span>
            </div>
          </div>
          <div className="confirm_help">
            <span onClick={() => navigate("/support")}>Need help?</span>
            <button
              className="btn"
              onClick={() => {
                setOpen(true);
              }}
            >
              Cancel Booking
            </button>
          </div>
        </div>
        <div className="confirm_right">
          <div className="right_row">
            <div className="right_row_title">
              {currentBooking.room?.hotel?.name}
            </div>
            <div className="right_row_nb">
              <span>Booking ID&ensp;</span>#{currentBooking.bookingNumber}
            </div>
          </div>
          <div className="right_row">
            <div className="right_row_location">
              <FaMapMarkerAlt
                style={{ color: "var(--secondary-color)", marginRight: "5px" }}
              />
              Lebanon, {currentBooking.room?.hotel?.location?.district?.name}{" "}
              District
            </div>
            <span className="booking_nb_text">Your Booking ID#</span>
          </div>
          <ConfirmRoomPreview
            room={currentBooking.room}
            price={currentBooking.totalPrice}
          />
          <img
            src={
              process.env.REACT_APP_API +
              "/rooms/" +
              currentBooking.room?.coverImage
            }
            className="hotel_img"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default Confirmed;
