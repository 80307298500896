import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { MdLocationOn } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import StarRatings from "react-star-ratings";
import { createTestimonial } from "../../../redux/testimonials/testimonialsActions";
import "./HotelBooking.scss";

function HotelBooking({ location, room, title, image, previous }) {
  const [modal, setModal] = useState(false);
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(0);
  const [isReviewed, setIsReviewed] = useState(false);
  const { loading, newTestimonialError, newTestimonialResponse, selfReviews } =
    useSelector((store) => store.testimonialsReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    setModal(false);
  }, [newTestimonialResponse]);

  const handleRate = () => {
    dispatch(
      createTestimonial({ hotel: modal, comment: review, stars: rating })
    );
  };

  useEffect(() => {
    if (selfReviews.length > 0) {
      selfReviews.forEach((rev) => {
        if (rev.hotel == room._id) {
          setIsReviewed(true);
        }
      });
    }
  }, [selfReviews]);

  return (
    <div className="booking_cont">
      {modal && (
        <div className="review_modal" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <span
              className="close"
              onClick={(e) => {
                e.stopPropagation();
                setModal(false);
              }}
            >
              &times;
            </span>
            <StarRatings
              numberOfStars={5}
              starDimension={20}
              starSpacing={2}
              changeRating={(v) => setRating(v)}
              starRatedColor={"gold"}
              rating={rating}
              starHoverColor={"gold"}
            />
            <textarea
              value={review}
              onChange={({ target }) => setReview(target.value)}
            />
            <button
              className={`btn ${loading ? "disabledBtn" : "enabledBtn"}`}
              onClick={handleRate}
              disabled={loading ? true : false}
            >
              {loading ? "loading..." : "Rate"}
            </button>
          </div>
        </div>
      )}
      <div className="detail_sub_container1">
        <img src={image} />
      </div>
      <div className="detail_sub_hotel">
        <div className="sub_hotel_header">
          <div className="sub_hotel_header_info">
            <div className="sub_hotel_title">{title}</div>

            <div className="sub_hotel_location">
              <i>
                <MdLocationOn size={"0.9rem"} />
              </i>
              {location}
            </div>
          </div>
        </div>

        <div className="sub_hotel_cont2">
          <div className="sub_cont2_sub">
            <div>{room.title}</div>
            <div className="cont2_sub_price">
              ${room.price}
              <span> /day </span>
            </div>
          </div>
        </div>
        {previous && (
          <div
            className={`write_review ${isReviewed && "reviewed"}`}
            onClick={(e) => e.stopPropagation()}
          >
            <button
              disabled={isReviewed}
              onClick={(e) => {
                e.stopPropagation();
                setModal(room?._id);
              }}
            >
              {isReviewed ? "Reviewed" : "Write a review"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default HotelBooking;
