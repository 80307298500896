import React, { useEffect, useState } from "react";
import DropDowns from "./DropDowns";
import "../Explore.scss";
import { GoLocation } from "react-icons/go";
import Filter from "./Budget/filter.js";
import { BsSearch } from "react-icons/bs";
import HotelSign from "../../../assets/images/Hotel_Sign.png";
import { useDispatch, useSelector } from "react-redux";
import { getDistricts } from "../../../redux/districts/districtsActions";
import { getAmenities } from "../../../redux/amenities/amenitiesActions";
import {
  getHotels,
  resetFilters,
  updateFilters,
} from "../../../redux/filters/filtersActions";

function ExploreHeader(props) {
  const districtReducerData = useSelector((state) => state.districtReducer);
  const amenitiesReducerData = useSelector((state) => state.amenitiesReducer);
  const { filterChange, district, amenities } = useSelector(
    (state) => state.filtersReducer
  );

  const [districtArray, setDistrictArray] = useState([]);
  const [amenitesArray, setAmenitesArray] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (districtReducerData.districts.length === 0) {
      dispatch(getDistricts());
    }
    if (amenitiesReducerData.amenities.length === 0) {
      dispatch(getAmenities());
    }
  }, []);

  useEffect(() => {
    const data = districtReducerData.districts.map((d) => {
      return { value: d._id, label: d.name };
    });
    data.unshift({ value: "", label: "Districts" });
    setDistrictArray(data);
  }, [districtReducerData.districts]);

  useEffect(() => {
    const data = amenitiesReducerData.amenities.map((d) => {
      return { value: d._id, label: d.name };
    });
    data.unshift({ value: "", label: "Amenities" });

    setAmenitesArray(data);
  }, [amenitiesReducerData.amenities]);

  // useEffect(() => {
  // if(district)
  // }
  // ,[filterChange])

  const updateDistricts = (district) => {
    dispatch(updateFilters("district", { district: district ?? [] }));
  };
  const updateAmenities = (amenities) => {
    dispatch(
      updateFilters("amenities", { amenities: amenities ? [amenities] : [] })
    );
  };
  const updatePrice = (minPrice, maxPrice) => {
    dispatch(
      updateFilters("price", { minPrice: minPrice, maxPrice: maxPrice })
    );
  };

  return (
    <div className="explore_header_container">
      <div className="explore_header_drop_container">
        <DropDowns
          option={districtArray}
          icon={<GoLocation size={"1rem"} />}
          labels={"Districts"}
          updateData={updateDistricts}
          selectedOption={props.selectedDistrict}
          setSelectedOption={props.setSelectedDistrict}
        />
        <DropDowns
          option={amenitesArray}
          icon={
            <img
              src={HotelSign}
              alt=""
              style={{ width: "1rem", height: "1rem" }}
            />
          }
          labels={"Amenities"}
          updateData={updateAmenities}
          selectedOption={props.selectedAmenity}
          setSelectedOption={props.setSelectedAmenity}
        />
      </div>

      <Filter
        className="header_budget"
        updatePrice={updatePrice}
        setResetPriceFunc={props.setResetPriceFunc}
      />
      <div
        className="explore_search_container_pc"
        onClick={() => {
          props.search(1, true);
        }}
      >
        <BsSearch size={"1.8rem"} />
      </div>
      <div
        className="reset_container_pc"
        onClick={() => {
          props.resetFilters();
        }}
      >
        Reset
      </div>
    </div>
  );
}

export default ExploreHeader;
