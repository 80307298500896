/** @format */

import { MdPendingActions } from "react-icons/md";
import {
  FECTH_FILTERED_HOTELS_REQUEST,
  FECTH_FILTERED_ROOMS_REQUEST,
  FETCH_FILTERED_HOTELS_FAIL,
  FETCH_FILTERED_HOTELS_SUCCESS,
  FETCH_FILTERED_ROOMS_FAIL,
  FETCH_FILTERED_ROOMS_SUCCESS,
  RESET_FILTERED_DATA,
  RESET_FILTERS,
  UPDATE_AMENITIES,
  UPDATE_SORT,
  UPDATE_DISTRICT,
  UPDATE_PRICE,
  UPDATE_TAGS,
  APPLY_FILTERS,
} from "./filtersTypes";

const initialState = {
  hotels: [],
  filteredHotelsError: "",
  currentHotelPage: 1,
  nextHotelPage: 1,
  lastHotelPage: 1,

  rooms: [],
  filteredRoomsError: "",
  currentRoomPage: 1,
  nextRoomPage: 1,
  lastRoomPage: 1,

  //filters
  tags: [],
  district: "",
  amenities: [],
  minPrice: "",
  maxPrice: "",
  sort: "",
  //this filters to be used and sent to backend
  filters: {},
  loading: false,
  //initialized to true to retrieve data on first load
  roomFilterChange: true,
  hotelFilterChange: true,
};

export const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_FILTERED_DATA:
      return {
        ...state,
        rooms: [],
        hotels: [],
        filters: [],
        tags: [],
        district: [],
        amenities: [],
        sort: "",
        minPrice: "",
        maxPrice: "",
        currentHotelPage: 1,
        nextHotelPage: 1,
        lastHotelPage: 1000000,
        currentRoomPage: 1,
        nextRoomPage: 1,
        filteredRoomsError: "",
        filteredHotelsError: "",
        filters: {},
        loading: false,
        roomFilterChange: true,
        hotelFilterChange: true,
      };

    case FECTH_FILTERED_HOTELS_REQUEST:
      return {
        ...state,
        loading: true,
        filteredHotelsError: "",
        hotelFilterChange: false,
      };
    case FETCH_FILTERED_HOTELS_SUCCESS:
      return {
        ...state,
        hotels: [...action.payload.hotels],
        lastHotelPage: action.payload.lastPage,
        currentHotelPage: action.payload.currentPage,
        nextHotelPage: action.payload.nextPage,
        loading: false,
      };
    case FETCH_FILTERED_HOTELS_FAIL:
      return {
        ...state,
        loading: false,
        filteredHotelsError: action.payload,
      };

    case FECTH_FILTERED_ROOMS_REQUEST:
      return {
        ...state,
        loading: true,
        filteredRoomsError: "",
        roomFilterChange: false,
      };
    case FETCH_FILTERED_ROOMS_SUCCESS:
      return {
        ...state,
        rooms: [...action.payload.rooms],
        lastRoomPage: action.payload.lastPage,
        currentRoomPage: action.payload.currentPage,
        nextRoomPage: action.payload.nextPage,
        loading: false,
      };
    case FETCH_FILTERED_ROOMS_FAIL:
      return {
        ...state,
        loading: false,
        filteredHotelsError: action.payload,
      };

    case UPDATE_AMENITIES:
      return {
        ...state,
        amenities: action.payload,
      };
    case UPDATE_DISTRICT:
      return {
        ...state,
        district: action.payload,
      };
    case UPDATE_PRICE:
      return {
        ...state,
        minPrice: action.payload.minPrice,
        maxPrice: action.payload.maxPrice,
      };
    case UPDATE_TAGS:
      return {
        ...state,
        tags: action.payload,
      };
    case UPDATE_SORT:
      return {
        ...state,
        filters: { ...state.filters, sort: action.payload },
        sort: action.payload,
        roomFilterChange: true,
        hotelFilterChange: true,
      };
    case APPLY_FILTERS:
      return {
        ...state,
        filters: action.payload,
        roomFilterChange: true,
        hotelFilterChange: true,
      };
    default:
      return state;
  }
};
