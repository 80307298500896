/** @format */
import axios from "axios";
import { MdCatchingPokemon } from "react-icons/md";
import { wishlistActions } from "./wishListReducer";

export const getFavorites = () => async (dispatch) => {
  try {
    dispatch(wishlistActions.fetchWishlistRequest());
    const response = await axios.get(
      process.env.REACT_APP_API + "/users/favorites"
    );
    dispatch(wishlistActions.fetchWishlistSuccess(response.data));
  } catch (e) {
    dispatch(wishlistActions.fetchWishlistFail(e.response));
  }
};

export const addHotelFavorites = (hotelId) => async (dispatch) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API + "/hotel/addToWishlist",
      {
        hotel: hotelId,
      }
    );
    dispatch(getFavorites());
  } catch (e) {
    console.log(e.response);
  }
};

export const removeHotelFavorites = (hotelId) => async (dispatch) => {
  try {
    const response = await axios.delete(
      process.env.REACT_APP_API + "/hotel/removeFromWishlist/" + hotelId
    );
    dispatch(getFavorites());
  } catch (e) {
    console.log(e.response);
  }
};

export const addRoomFavorites = (RoomId) => async (dispatch) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API + "/room/addToWishlist",
      {
        room: RoomId,
      }
    );
    dispatch(getFavorites());
  } catch (e) {
    console.log(e.response);
  }
};

export const rmvRoomFavorites = (roomId) => async (dispatch) => {
  try {
    const response = await axios.delete(
      process.env.REACT_APP_API + "/room/removeFromWishlist/" + roomId
    );
    dispatch(getFavorites());
  } catch (e) {
    console.log(e.response);
  }
};
