/** @format */

import {
  FECTH_HOTEL_REQUEST,
  FETCH_HOTEL_SUCCESS,
  FETCH_HOTEL_FAIL,
} from "./hotelTypes";
import axios from "axios";

export const getHotelDetails = (id) => async (dispatch) => {
  dispatch({
    type: FECTH_HOTEL_REQUEST,
  });
  try {
    const res = await axios.get(process.env.REACT_APP_API + "/hotel/" + id);
    dispatch({
      type: FETCH_HOTEL_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: FETCH_HOTEL_FAIL,
    });
  }
};
// export const getfavoriteHotel = (id) => async (dispatch) => {
//   dispatch({
//     type: FECTH_HOTEL_REQUEST,
//   });
//   try {
//     const res = await axios.get(
//       process.env.REACT_APP_API + "/hotel/?isFavorite=true" + id
//     );
//     dispatch({
//       type: FETCH_HOTEL_SUCCESS,
//       payload: res.data,
//     });
//   } catch (error) {
//     console.log(error);
//     dispatch({
//       type: FETCH_HOTEL_FAIL,
//     });
//   }
// };
