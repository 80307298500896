/** @format */

import React, { useState } from "react";
import "./EmailSubscription.scss";
import axios from "axios";

function EmailSubscription() {
  const [email, setEmail] = useState("");
  const validEmail = new RegExp(
    "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
  );

  const [emailResponse, setEmailResponse] = useState("");
  return (
    <div className="email_container">
      <div className="title email_title">
        Subscribe to our newsletter <br />
        &#38; Get the latest news
      </div>
      <form className="email_input" onSubmit={(e) => e.preventDefault()}>
        <input
          type="email"
          value={email}
          placeholder="Enter your email address"
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          className="btn btn-2"
          onClick={() => {
            if (email && validEmail.test(email)) {
              axios
                .post(process.env.REACT_APP_API + "/subscription/subscribe", {
                  email,
                })
                .then(() => {
                  setEmailResponse("You subscribed succesfully.");
                })
                .catch((e) => {
                  setEmailResponse(e.response.data.message);
                });
              setEmail("");
            }
          }}
        >
          Subscribe
        </button>
      </form>
      <div className="emailResp">{emailResponse}</div>
    </div>
  );
}

export default EmailSubscription;
