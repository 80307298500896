import React, { useState, useEffect } from "react";
import Select from "react-select";

function DropDowns({
  option,
  icon,
  labels,
  updateData,
  selectedOption,
  setSelectedOption,
}) {
  useEffect(() => {
    setSelectedOption(option[0]);
  }, [option]);
  return (
    <div className="dropdown_container">
      <Select
        value={selectedOption}
        className="dropdown_1"
        onChange={(e) => {
          setSelectedOption(e);
          updateData(e.value);
        }}
        options={option}
        theme={(theme) => ({
          ...theme,
          borderRadius: "0.5vw",
          borderWidth: "5px  !important",
          borderColor: "#F3F1F0 !important",
          colors: {
            ...theme.colors,
            text: "var(--title-color)",
            primary25: "var(--secondary-color)",
            primary: "var(--text-color-one)",
          },
          fontFamily: "Outfit",
        })}
        isSearchable={false}
      />
      <div className="icon_dropdown">{icon}</div>
    </div>
  );
}

export default DropDowns;
