/** @format */

import {
  FETCH_TESTIMONIALS_REQUEST,
  FETCH_TESTIMONIALS_SUCCESS,
  FETCH_TESTIMONIALS_FAIL,
  FETCH_HOTEL_TESTIMONIALS_REQUEST,
  FETCH_HOTEL_TESTIMONIALS_SUCCESS,
  FETCH_HOTEL_TESTIMONIALS_FAIL,
  CREATE_TESTIMONIAL_REQUEST,
  CREATE_TESTIMONIAL_SUCCESS,
  CREATE_TESTIMONIAL_FAIL,
  GET_USER_REVIEWS_REQUEST,
  GET_USER_REVIEWS_SUCCESS,
  GET_USER_REVIEWS_FAIL,
} from "./testimonialsTypes";

import axios from "axios";

export const fetchTestimonialsRequest = () => {
  return { type: FETCH_TESTIMONIALS_REQUEST };
};

export const fetchTestimonialsSuccess = (data) => {
  return {
    type: FETCH_TESTIMONIALS_SUCCESS,
    payload: data,
  };
};
export const fetchTestimonialsFail = (error) => {
  return {
    type: FETCH_TESTIMONIALS_FAIL,
    payload: error,
  };
};

export const fetchHotelTestimonialsRequest = () => {
  return { type: FETCH_HOTEL_TESTIMONIALS_REQUEST };
};

export const fetchHotelTestimonialsSuccess = (data) => {
  return {
    type: FETCH_HOTEL_TESTIMONIALS_SUCCESS,
    payload: data,
  };
};
export const fetchHotelTestimonialsFail = (error) => {
  return {
    type: FETCH_HOTEL_TESTIMONIALS_FAIL,
    payload: error,
  };
};

export const getGlobalTestimonials = () => async (dispatch) => {
  dispatch(fetchTestimonialsRequest());
  try {
    const response = await axios.get(
      process.env.REACT_APP_API + "/testimonial/global"
    );
    dispatch(fetchTestimonialsSuccess(response.data));
  } catch (e) {
    console.log(e);
    dispatch(fetchTestimonialsFail(e));
  }
};

export const getHotelTestimonials = (id) => async (dispatch) => {
  dispatch(fetchHotelTestimonialsRequest());
  try {
    const response = await axios.get(
      process.env.REACT_APP_API + "/testimonial/" + id
    );
    dispatch(fetchHotelTestimonialsSuccess(response.data));
  } catch (e) {
    console.log(e);
    dispatch(fetchHotelTestimonialsFail(e));
  }
};

export const createTestimonial =
  ({ hotel, comment, stars }) =>
  async (dispatch) => {
    dispatch({ type: CREATE_TESTIMONIAL_REQUEST });
    try {
      const response = await axios.post(
        process.env.REACT_APP_API + "/testimonial/",
        { hotel, comment, stars }
      );
      dispatch({ type: CREATE_TESTIMONIAL_SUCCESS, payload: response });
    } catch (e) {
      console.log(e);
      dispatch({ type: CREATE_TESTIMONIAL_FAIL, payload: e });
    }
  };

export const getReviewsForUser = () => async (dispatch) => {
  dispatch({ type: GET_USER_REVIEWS_REQUEST });
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_API + "/testimonial/getSelfReviews"
    );
    console.log(data);
    dispatch({ type: GET_USER_REVIEWS_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
    e.message && dispatch({ type: GET_USER_REVIEWS_FAIL });
  }
};
