/** @format */

import axios from "axios";
import { notificationsActions } from "./notificationsReducer";

export const getNotifications = () => async (dispatch) => {
  try {
    dispatch(notificationsActions.fetchNotificationsRequest());
    const response = await axios.get(
      process.env.REACT_APP_API + "/notification"
    );
    dispatch(notificationsActions.fetchNotificationsSuccess(response.data));
  } catch (e) {
    dispatch(notificationsActions.fetchNotificationsFail(e.response.data));
  }
};

export const readAllNotifications = () => async (dispatch) => {
  try {
    await axios.get(process.env.REACT_APP_API + "/notification/read");
    dispatch(notificationsActions.readAllNotifications());
  } catch (e) {
    console.log(e);
  }
};

export const deleteAllNotifications = () => async (dispatch) => {
  try {
    let res = await axios.delete(process.env.REACT_APP_API + "/notification");
    dispatch(notificationsActions.deleteAllNotifications());
  } catch (e) {
    console.log(e);
  }
};
