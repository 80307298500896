/** @format */

import { BrowserRouter as Router } from "react-router-dom";
import "./App.scss";
import PagesSwitch from "./PagesSwitch";
import { io } from "socket.io-client";
import jwtDecode from "jwt-decode";
import isAuth from "./utils/isAuth";
import setAuthToken from "./utils/setAuthToken";
import { useDispatch } from "react-redux";
import { LOGIN_SUCCESS } from "./redux/user/userTypes";
import { GoogleOAuthProvider } from "@react-oauth/google";

let socket = null;

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

if (isValidToken(localStorage.getItem("token"))) {
  socket = io(process.env.REACT_APP_API, {
    auth: {
      token: localStorage.getItem("token"),
    },
  });
}

export const setSocket = (data) => {
  socket = data;
};

if (localStorage.getItem("token")) {
  setAuthToken(localStorage.getItem("token"));
}

function App() {
  const dispatch = useDispatch();

  if (localStorage.getItem("user")) {
    dispatch({
      type: LOGIN_SUCCESS,
      payloadData: JSON.parse(localStorage.getItem("user")),
    });
  }
  isAuth(dispatch);
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router basename="/">
        <PagesSwitch />
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
export { socket };
