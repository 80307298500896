/** @format */

import React from "react";
import { useEffect, useRef } from "react";
import "./Testimonial.scss";
import { CgQuote } from "react-icons/cg";
import StarRatings from "react-star-ratings/build/star-ratings";
import { useDispatch, useSelector } from "react-redux";

function Testimonial({
  name,
  stars,
  avatar,
  hotel,
  location,
  text,
  amenities,
}) {
  return (
    <div className="testimonial_cont">
      <div className="testimonial_cart">
        <div className="testimonial_quote">
          <CgQuote size={70} color={"var(--primary-color)"} />
        </div>
        <div className="testimonial_row1">
          <img src={avatar} alt="" />
          <div className="testimonial_name_cont">
            <div className="testimonial_name">
              <div className="user_name">{name}</div>
              <div>
                <StarRatings
                  rating={1}
                  starRatedColor={"#F8B806"}
                  numberOfStars={1}
                  name="rating"
                  starDimension={"15"}
                  starSpacing={"0"}
                />
              </div>
              <span style={{ marginTop: "0.4rem" }}>({stars})</span>
            </div>
            <div className="testimonial_went_to">
              Went to:{" "}
              <span>
                {hotel}, {location}
              </span>
            </div>
          </div>
        </div>
        <div className="testimonial_row2">"{text}"</div>
        <div className="testimonial_row3">
          <div className="testimonial_amenities_title">
            Best amenities in {hotel}:
          </div>
          <div className="testimonial_amenities">
            {amenities?.map((a, i) => {
              if (i > 2) return;
              return (
                //the anchor is to display amenity name on hover
                <a title={a.name}>
                  <img
                    src={process.env.REACT_APP_API + "/amenities/" + a.icon}
                    alt={a.name}
                    key={i}
                    className="amenity"
                  />
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
