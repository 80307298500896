class Log {
    constructor(opts) {
      this.minpos = opts.minpos || 0;
      this.maxpos = opts.maxpos || 100;
  
      this.minval = opts.minval || 0;
      this.maxval = opts.maxval || 2000;
  
      this.scale = ((this.maxval - this.minval) / (this.maxpos - this.minpos));
    }
  
  
    value(position) {
      return ((position - this.minpos) * this.scale + this.minval);
    }
  
  
    position(value) {
      return (this.minpos + (value- this.minval) / this.scale);
    }

  }
  
  
  export default Log;