/** @format */

import axios from "axios";
import { bookingsActions } from "./bookingsReducer";

export const getBookingsForUser = () => async (dispatch) => {
  try {
    dispatch(bookingsActions.fetchBookingsRequest());
    const response = await axios.get(
      process.env.REACT_APP_API + "/booking/user"
    );
    dispatch(bookingsActions.fetchBookingsSuccess(response.data));
  } catch (e) {
    dispatch(bookingsActions.fetchBookingsFail(e.response.data));
  }
};
