import React from "react";
import DetailCont from "../DetailCont";
import "./hoteldetails.scss";
import Loading from "../../AssetComponents/Loading/Loading";

function HotelDetails(props) {
  return (
    <div className="hotel_details_container">
      {props.loading ? (
        <Loading />
      ) : (
        props?.hotels?.map((hotel, i) => {
          return hotel?.isActive ? (
            <DetailCont
              type={"Hotel"}
              image={hotel?.coverImage}
              title={hotel?.name}
              location={hotel?.location?.district?.name}
              rooms={hotel?.rooms}
              Id={hotel?._id}
              key={i}
              coverImage={hotel?.coverImage}
            />
          ) : null;
        })
      )}
    </div>
  );
}

export default HotelDetails;
