import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";

function VerifyEmail() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  let token = searchParams.get("token");
  useEffect(() => {
    try {
      axios.post(process.env.REACT_APP_API + "/verifyEmail", { token: token });
    } catch (e) {
      console.log(e);
    }
  }, []);
  return (
    <div
      style={{
        width: "100%",
        height: "60vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ fontSize: "3rem", textAlign: "center" }}>
        Your Email has been verified!
      </div>
      <button
        className="btn"
        style={{ fontSize: "1.5rem", marginTop: "1rem" }}
        onClick={() => {
          navigate("/");
        }}
      >
        Home
      </button>
    </div>
  );
}

export default VerifyEmail;
