import React, { useEffect, useState } from "react";
import ExploreHeader from "./ExploreHeader/ExploreHeader";
import { FaCrown } from "react-icons/fa";
import HotelDetails from "./Hotels/HotelDetails";
import SortDropDown from "./SortDropDown";
import Map from "./Map";
import RoomDetails from "./Rooms/RoomDetails";
import { BsSearch } from "react-icons/bs";
import { getActiveTags } from "../../redux/tags/tagsActions";
import { useSelector, useDispatch } from "react-redux";
import {
  applyfilters,
  getHotels,
  getRooms,
  resetFilters,
  updateFilters,
} from "../../redux/filters/filtersActions";
import PaginationComp from "./PaginationComp";
import { useLocation } from "react-router-dom";
function Explore() {
  //location is used to retrieve navigation data
  const location = useLocation();
  //when navigating from another page
  const [isRoomActive, setisRoomActive] = useState(
    location.state?.exploreType == "rooms" ? true : false
  );

  const [tagsi, setTags] = useState([]);
  const dispatch = useDispatch();
  const { activeTags, loading: tagsLoading } = useSelector(
    (state) => state.tagsReducer
  );

  const defaultDistrict = { value: "", label: "Districts" };
  const defaultAmenity = { value: "", label: "Amenities" };

  const [selectedDistrict, setSelectedDistrict] = useState(defaultDistrict);
  const [selectedAmenity, setSelectedAmenity] = useState(defaultAmenity);
  const [resetPriceFunc, setResetPriceFunc] = useState();
  //when coming from home page
  const navigationTags = location.state?.defaultTags;
  const navigationDistrict = location.state?.defaultDistrict;
  const [initFromOutside, setInitFromOutside] = useState(
    location.state?.outerInit ? true : false
  );
  const {
    hotels,
    filteredHotelsError,
    currentHotelPage,
    nextHotelPage,
    lastHotelPage,
    rooms,
    filteredRoomsError,
    currentRoomPage,
    nextRoomPage,
    lastRoomPage,
    tags,
    district,
    amenities,
    minPrice,
    maxPrice,
    loading,
    roomFilterChange,
    hotelFilterChange,
    filters,
    sort,
  } = useSelector((state) => state.filtersReducer);

  useEffect(() => {
    if (initFromOutside) {
      //force fetch when coming from home page
      const Filters = {
        amenities: amenities ?? [],
        tags: navigationTags ?? [],
        district: navigationDistrict?.value ?? [],
        minPrice: minPrice ?? "",
        maxPrice: maxPrice ?? "",
        sort: "",
      };
      dispatch(
        updateFilters("district", { district: navigationDistrict?.value })
      );
      dispatch(updateFilters("tags", { tags: navigationTags }));
      dispatch(getRooms(1, roomFilterChange, Filters));

      setTimeout(() => {
        setSelectedDistrict(navigationDistrict);
        dispatch(applyfilters(Filters));
      }, 500);
      //we are done with everything to do with navigation
      //seeting it to false allows normal behavior to run
      setInitFromOutside(false);
    }
    return () => {
      //when navigating away from page
      dispatch(resetFilters());
    };
  }, []);

  useEffect(() => {
    const data = activeTags.map((tag) => {
      if (navigationTags && navigationTags.includes(tag._id))
        tag.clicked = true;
      else tag.clicked = false;
      return tag;
    });
    setTags(data);
  }, [activeTags]);

  useEffect(() => {
    if (!initFromOutside) {
      isRoomActive ? fetchData(currentRoomPage) : fetchData(currentHotelPage);
    }
  }, [isRoomActive]);

  useEffect(() => {
    if (!loading) {
      window.scroll(0, 0);
    }
  }, [loading]);

  useEffect(() => {
    if (activeTags.length === 0) {
      dispatch(getActiveTags());
    }
  }, []);

  const makeButtonActive = (id) => {
    if (id === 0) {
      setisRoomActive(false);
    } else {
      setisRoomActive(true);
    }
  };
  function makeTagActive(id) {
    const data = tagsi.map((tag) => {
      if (tag._id === id) {
        tag.clicked = !tag.clicked;
      }
      return tag;
    });
    setTags(data);
    const activeTag = data.filter((tag) => tag.clicked).map((tag) => tag._id);
    dispatch(updateFilters("tags", { tags: activeTag }));
  }

  function makeAllTagsInactive() {
    tagsi.map((tag) => {
      tag.clicked = false;
      return tag;
    });
    setTags(tagsi);
  }

  return (
    <div className="explore_main_container globalContainer">
      <div className="explore_filter_header_resp">
        <div className="explore_resp_filter_main">
          <div className="filter_room_hotels">
            <button
              id="btn1"
              className={
                !isRoomActive
                  ? "filter_button1 active_btn"
                  : "filter_button1 not_active_btn"
              }
              onClick={() => makeButtonActive(0)}
            >
              Hotels
            </button>
            <button
              id="btn2"
              className={
                isRoomActive
                  ? "filter_button2 active_btn"
                  : "filter_button2 not_active_btn"
              }
              onClick={() => makeButtonActive(1)}
            >
              Rooms
            </button>
          </div>

          <SortDropDown addMargin={true} sortChange={sortChange} />
        </div>
        <div
          className="explore_search_container_phone"
          onClick={(e) => {
            e.preventDefault();
            fetchData(1, true);
          }}
        >
          <BsSearch className="search_icon" size={"1.8rem"} />
        </div>
      </div>
      <ExploreHeader
        search={fetchData}
        resetFilters={resetFiltersAndFetchData}
        setSelectedDistrict={setSelectedDistrict}
        setSelectedAmenity={setSelectedAmenity}
        selectedDistrict={selectedDistrict}
        selectedAmenity={selectedAmenity}
        setResetPriceFunc={setResetPriceFunc}
      />
      <div className="explore_2nd_Cont">
        <div className="explore_2_sub1">
          <div className="tags_filters">
            <div className="explore2_sub1_filter">
              {tagsi.map((tag, i) => {
                return (
                  <div
                    className={`filter_tags ${tag.clicked && "active"}`}
                    onClick={() => makeTagActive(tag._id)}
                  >
                    #{tag.name}
                    {tag.isHot && (
                      <div className="filter_crown">
                        {" "}
                        <FaCrown className="crown_icon" size={"1rem"} />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="filter_room_hotels">
              <button
                id="btn1"
                className={
                  !isRoomActive
                    ? "filter_button1 active_btn"
                    : "filter_button1 not_active_btn"
                }
                onClick={() => makeButtonActive(0)}
              >
                Hotels
              </button>
              <button
                id="btn2"
                className={
                  isRoomActive
                    ? "filter_button2 active_btn"
                    : "filter_button2 not_active_btn"
                }
                onClick={() => makeButtonActive(1)}
              >
                Rooms
              </button>
            </div>

            <SortDropDown sortChange={sortChange} />

            {!isRoomActive ? (
              <HotelDetails hotels={hotels} loading={loading} />
            ) : (
              <RoomDetails rooms={rooms} loading={loading} />
            )}
            {!isRoomActive ? (
              <PaginationComp
                page={currentHotelPage}
                totalPages={lastHotelPage}
                onClick={fetchData}
              />
            ) : (
              <PaginationComp
                page={currentRoomPage}
                totalPages={lastRoomPage}
                onClick={fetchData}
              />
            )}
          </div>
        </div>
        <div className="explore2_sub2">
          <div className="map_parent">
            {/* <iframe
                title="Location"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3313.782332304788!2d35.90593540660706!3d33.84372363243248!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzPCsDUwJzM2LjQiTiAzNcKwNTQnMzEuMiJF!5e0!3m2!1sen!2slb!4v1625080620074!5m2!1sen!2slb"
                width="100%"
                height="450"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
              ></iframe> */}
            <Map hotel={hotels} />
          </div>
        </div>
      </div>
    </div>
  );

  function sortChange(recievedSort) {
    dispatch(updateFilters("sort", { sort: recievedSort }));
    fetchData(1, false, recievedSort);
  }

  function fetchData(page = 1, isSearch = false, recievedSort = "") {
    //always apply old filters unless sarch button is clicked
    var Filters = {
      amenities: filters.amenities ?? [],
      tags: filters.tags ?? [],
      district: filters.district ?? [],
      minPrice: filters.minPrice ?? "",
      maxPrice: filters.maxPrice ?? "",
      sort: recievedSort != "" ? recievedSort : sort,
    };

    if (isSearch) {
      Filters = {
        amenities: amenities ?? [],
        tags: tags ?? [],
        district: district ?? [],
        minPrice: minPrice ?? "",
        maxPrice: maxPrice ?? "",
        sort: filters.sort ?? "",
      };
      dispatch(applyfilters(Filters));
    }
    if (
      isRoomActive &&
      !loading &&
      (isSearch || roomFilterChange || page != currentRoomPage || recievedSort)
    ) {
      dispatch(getRooms(page, roomFilterChange, Filters));
    } else if (
      !isRoomActive &&
      !loading &&
      (isSearch ||
        hotelFilterChange ||
        page != currentHotelPage ||
        recievedSort)
    ) {
      dispatch(getHotels(page, hotelFilterChange, Filters));
    }
  }

  function resetFiltersAndFetchData() {
    var Filters = {
      amenities: [],
      tags: [],
      district: [],
      minPrice: "",
      maxPrice: "",
      sort: "",
    };
    setSelectedAmenity(defaultAmenity);
    setSelectedDistrict(defaultDistrict);

    resetPriceFunc({
      min: 0,
      max: 100,
    });
    makeAllTagsInactive();
    dispatch(resetFilters());
    if (isRoomActive) {
      dispatch(getRooms(1, roomFilterChange, Filters));
    } else if (!isRoomActive) {
      dispatch(getHotels(1, hotelFilterChange, Filters));
    }
  }
}

export default Explore;
