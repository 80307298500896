/** @format */

import React, { useState } from "react";
import { MdLocationOn } from "react-icons/md";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import "./HotelInWishlist.scss";
import HeartComponent from "../AssetComponents/HeartComponent";
import { useNavigate } from "react-router-dom";
function HotelInWishlist(props) {
  const navigate = useNavigate();
  return (
    <>
      <div
        className={
          props.available && props.type == "Room"
            ? "Hotel_detail_container"
            : props.type == "Hotel"
            ? "Hotel_detail_container"
            : "Hotel_detail_container unavailable"
        }
        onClick={() => navigate("/hotel/" + props.HotelId)}
        style={{ cursor: "pointer" }}
      >
        <div className="detail_sub_container1">
          <img
            src={
              process.env.REACT_APP_API +
              (props.type == "Room" ? "/rooms/" : "/hotels/") +
              props.src
            }
          />
        </div>
        {props.type == "Hotel" && (
          <div className="detail_sub_hotel">
            <div className="sub_hotel_header">
              <div className="sub_hotel_header_info">
                <div className="sub_hotel_title">{props.title} </div>

                <div className="sub_hotel_location">
                  <i>
                    <MdLocationOn size={"0.9rem"} />{" "}
                  </i>
                  {props.location}
                </div>
              </div>

              <HeartComponent Hotel_Id={props.HotelId} />
            </div>
          </div>
        )}
      </div>

      <div className="detail_container_resp">
        {props.type == "Hotel" && (
          <div className="detail_sub_hotel">
            <div className="sub_hotel_header">
              <div className="detail_sub_container1">
                <img
                  src={
                    process.env.REACT_APP_API +
                    (props.type == "Room" ? "/rooms/" : "/hotels/") +
                    props.src
                  }
                />
              </div>
              <div className="sub_hotel_header_info">
                <div className="sub_hotel_title">{props.title} </div>

                <div className="sub_hotel_location">
                  <i>
                    <MdLocationOn className="location_icon" size={"0.9rem"} />{" "}
                  </i>
                  {props.location}
                </div>
              </div>
              <HeartComponent Hotel_Id={props.HotelId} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default HotelInWishlist;
