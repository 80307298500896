/** @format */

import React, { useState } from "react";
import logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { AiFillInstagram, AiOutlineInstagram } from "react-icons/ai";
import { FiFacebook } from "react-icons/fi";
import { FaFacebookF } from "react-icons/fa";
import { RiWhatsappLine, RiWhatsappFill } from "react-icons/ri";
import { SiTiktok } from "react-icons/si";
import { BsTiktok } from "react-icons/bs";
import "./Footer.css";

//todo: add href links

function Footer() {
  const [hover1, setHover1] = useState(true);
  const [hover2, setHover2] = useState(true);
  const [hover3, setHover3] = useState(true);
  const [hover4, setHover4] = useState(true);

  return (
    <footer className="footer globalContainer">
      <div className="Footer_Upper">
        <div className="footer_logo">
          <img src={logo} alt="Logo" />
        </div>
        <div className="info-links">
          <a
            target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLSdYqrT6fS1dTTDsS4gwIdAA_7VoI8fNoZRYXXEQaOL-CLjHsQ/viewform?usp=pp_url"
            className="register_hotel_link"
          >
            Register Your Hotel
          </a>
          <Link to="/explore">Hotels</Link>
          <Link to="/about">About Us</Link>
          <Link to="/support">Support</Link>
        </div>
      </div>
      <hr />
      <div className="socials">
        <a
          target="_blank"
          href={"https://wa.me/12133733555"}
          onMouseEnter={() => setHover1(false)}
          onMouseLeave={() => setHover1(true)}
        >
          {hover1 ? (
            <RiWhatsappLine
              style={{ color: "var(--secondary-color)" }}
              size={"20"}
            />
          ) : (
            <RiWhatsappFill
              style={{ color: "var(--secondary-color)" }}
              size={"20"}
            />
          )}
        </a>
        <a
          target="_blank"
          href={"https://instagram.com/ooda.online"}
          onMouseEnter={() => setHover2(false)}
          onMouseLeave={() => setHover2(true)}
        >
          {hover2 ? (
            <AiOutlineInstagram
              style={{ color: "var(--secondary-color)" }}
              size={"20"}
            />
          ) : (
            <AiFillInstagram
              style={{ color: "var(--secondary-color)" }}
              size={"20"}
            />
          )}
        </a>
        <a
          target="_blank"
          href="https://www.facebook.com/ooda.online"
          onMouseEnter={() => setHover3(false)}
          onMouseLeave={() => setHover3(true)}
        >
          {hover3 ? (
            <FiFacebook
              style={{ color: "var(--secondary-color)" }}
              size={"20"}
            />
          ) : (
            <FaFacebookF
              style={{ color: "var(--secondary-color)" }}
              size={"20"}
            />
          )}
        </a>
        <a
          target="_blank"
          href="https://www.tiktok.com/@ooda.online"
          onMouseEnter={() => setHover4(false)}
          onMouseLeave={() => setHover4(true)}
        >
          {hover4 ? (
            <BsTiktok style={{ color: "var(--secondary-color)" }} size={"20"} />
          ) : (
            <SiTiktok style={{ color: "var(--secondary-color)" }} size={"20"} />
          )}
        </a>
      </div>
      {/* <div className="copyright">&copy; Copyright 2022 - Salem Group</div> */}
    </footer>
  );
}

export default Footer;
