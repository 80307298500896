import React, { useEffect } from "react";
import Input from "./Input";
import { GoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { login, signup } from "../../redux/user/userActions";
import logo from "../../assets/images/logo.png";
import jwt_decode from "jwt-decode";

//todo: finalize google login

const initialFormData = { fname: "", lname: "", email: "", password: "" };

const Form = ({ isLogin, title, caption, func }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFromData] = useState(initialFormData);
  const [passwordError, setPasswordError] = useState(false);
  const [googleError, setGoogleError] = useState("");
  const passwordRegex = new RegExp("/^.{8,}$/");

  const validatePass = () => {
    return passwordRegex.test(formData.password);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLogin) {
      const data = {
        email: formData.email,
        password: formData.password,
      };
      dispatch(login(data, navigate));
    } else {
      if (validatePass()) {
        setPasswordError(false);
        dispatch(signup(formData));
      } else {
        setPasswordError(false);
        dispatch(signup(formData));
      }
    }
  };

  const resetForm = () => {
    setFromData(initialFormData);
  };

  const { loading, error, isAuthenticated, error1, response } = useSelector(
    (state) => state.userReducer
  );

  const handleChange = (e) => {
    setFromData({ ...formData, [e.target.name]: e.target.value });
  };

  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const googleSuccess = async (res) => {
    try {
      setGoogleError(""); //remove google signup error
      var decoded = jwt_decode(res.credential);
      const data = {
        email: decoded.email,
        firstName: decoded.given_name,
        lastName: decoded.family_name,
        avatar: decoded.picture,
        googleTokenId: res.credential,
      };
      dispatch(login(data, navigate));
    } catch (error) {
      console.log(error);
    }
  };

  const googleFailure = (e) => {
    console.log(e?.error, e?.details);
    // set error for google signup
    setGoogleError("There was an error signing in with google.");
  };

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     navigate("/");
  //   }
  // }, [isAuthenticated]);

  return (
    <div className={isLogin ? "left" : "right"} id={isLogin ? "left" : "right"}>
      <div className="content">
        <img
          src={logo}
          alt="logo"
          className="logo"
          onClick={() => {
            navigate("/");
          }}
        />
        <div className="title">{title}</div>
        <div className="caption">
          {caption}{" "}
          {!isLogin && (
            <>
              <span
                onClick={() => {
                  func();
                }}
                className="slide-button"
              >
                Login
              </span>
            </>
          )}
        </div>
        {isLogin && (
          <>
            <GoogleLogin
              className="login-with-google"
              onSuccess={googleSuccess}
              onFailure={googleFailure}
            />
            <div className="or">Or</div>
          </>
        )}
        <form
          action={isLogin ? "login" : "signup"}
          method="post"
          onSubmit={handleSubmit}
        >
          <div className="error">{googleError}</div>
          {!isLogin && (
            <>
              <div className="error">{error1}</div>
              <div className="error">{response}</div>
              {passwordError && (
                <div className="error">
                  Passwords must be at least 8 characters, contain 1 letter and
                  1 number.
                </div>
              )}
              <div className="row">
                <Input
                  name="firstName"
                  label="Firstname"
                  type="text"
                  placeholder="Enter your firstname"
                  handleChange={handleChange}
                  style={{ marginRight: "10px", width: "90%" }}
                />
                <Input
                  name="lastName"
                  label="Lastname"
                  type="text"
                  placeholder="Enter your lastname"
                  handleChange={handleChange}
                />
              </div>
            </>
          )}
          {isLogin && <div className="error">{error}</div>}
          <Input
            name="email"
            label="Email"
            type="text"
            placeholder="Enter your email"
            handleChange={handleChange}
          />
          <Input
            name="password"
            label="Password"
            type="password"
            placeholder="Enter your password"
            handleChange={handleChange}
          />
          <button type="submit" className="button">
            {isLogin ? "Login" : "Signup"}
          </button>
        </form>
        {isLogin && (
          <>
            <div className="switch">
              <span>
                Don't have an account?{" "}
                <span
                  id="signup-slide-button"
                  className="slide-button"
                  onClick={() => {
                    func();
                  }}
                >
                  Sign Up
                </span>
              </span>
              <span /* onClick={props.func} */ className="forgot-pass">
                Forgot password?
              </span>
            </div>
          </>
        )}
        {!isLogin && (
          <>
            <div className="or">Or</div>
            <GoogleLogin
              clientId={googleClientId}
              className="login-with-google"
              onSuccess={googleSuccess}
              onFailure={googleFailure}
              cookiePolicy="single_host_origin"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Form;
