/** @format */

import React, { Suspense, useLayoutEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Loading from "./components/AssetComponents/Loading/Loading";
import Header from "./components/AssetComponents/Header/Header";
import Footer from "./components/AssetComponents/Footer/Footer";
import Home from "./components/Home/Home";
import Login from "./components/Login/Login";
import lgin from "./components/Login/Login";
import Hotel from "./components/individual hotel page/Hotel";
import Confirmed from "./components/Confirmed/Confirmed";
import Explore from "./components/Explore/Explore";
import Support from "./components/Support/Support";
import VerifyEmail from "./components/VerifyEmail/VerifyEmail";
import About from "./components/About/About";

//todo: set dynamic individual hotel page based on hotel name/id

function PagesSwitch() {
  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Suspense fallback={<Loading />}>
      {/* Header component */}
      {location.pathname !== "/login" && <Header />}

      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/login" exact element={<Login ActiveSlide={true} />} />
        <Route path="/signup" exact element={<Login ActiveSlide={false} />} />
        <Route path="/hotel/:id" exact element={<Hotel />} />
        <Route path="/confirmed/:id" exact element={<Confirmed />} />
        <Route path="/explore" exact element={<Explore />} />
        <Route path="/support" exact element={<Support />} />
        <Route path="/verifyEmail" exact element={<VerifyEmail />} />
        <Route path="/about" exact element={<About />} />
        <Route path="*" exact element={<Navigate to="/" />} />
      </Routes>

      {/* Footer component*/}
      {location.pathname !== "/login" && <Footer />}
    </Suspense>
  );
}

export default PagesSwitch;
