import {
  FECTH_DISTRICTS_REQUEST,
  FETCH_DISTRICTS_FAIL,
  FETCH_DISTRICTS_SUCCESS,
} from "./districtsTypes";

const initialState = {
  districts: [],
  loading: false,
  error: "",
};

export const districtsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FECTH_DISTRICTS_REQUEST:
      return {
        ...state,
        districts: [],
        loading: true,
        error: "",
      };

    case FETCH_DISTRICTS_SUCCESS:
      return {
        ...state,
        districts: action.payload,
        loading: false,
        error: "",
      };
    case FETCH_DISTRICTS_FAIL:
      return {
        ...state,
        districts: [],
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
