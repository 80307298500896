/** @format */

import {
  LOGIN_REQUEST,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  UPDATE_USER,
} from "./userTypes";
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import { setSocket, socket } from "../../App";
import { io } from "socket.io-client";

// action for request success and fail

export const login = (data, navigate) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });

  try {
    const res = await axios.post(process.env.REACT_APP_API + "/login", data);

    localStorage.setItem("token", res.data.token);
    localStorage.setItem("user", JSON.stringify(res.data.result));
    dispatch({
      type: LOGIN_SUCCESS,
      payloadToken: res.data.token,
      payloadData: res.data.result,
    });
    setAuthToken(res.data.token);
    setSocket(
      io(process.env.REACT_APP_API, {
        auth: {
          token: res.data.token,
        },
      })
    );
    navigate(-1);
  } catch (error) {
    console.log(error);
    dispatch({
      type: LOGIN_FAIL,
      payload:
        error.response && error.response.data && error.response.data.message,
    });
  }
};
//    dispatch(myFunc(params))
export const signup =
  ({ firstName, lastName, email, password }) =>
  async (dispatch) => {
    dispatch({ type: SIGNUP_REQUEST });
    try {
      const res = await axios.post(process.env.REACT_APP_API + "/signup", {
        firstName,
        lastName,
        email,
        password,
      });
      dispatch({ type: SIGNUP_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: SIGNUP_FAIL, payload: error.response.data.message });
    }
  };

export const update_info =
  ({ avatar, firstName, lastName }) =>
  async (dispatch) => {
    try {
      let img_file = new FormData();
      img_file.append("avatar", avatar);
      img_file.append("firstName", firstName);
      img_file.append("lastName", lastName);
      const res = await axios.put(
        process.env.REACT_APP_API + "/users",
        img_file
      );
      const user = JSON.parse(localStorage.getItem("user"));
      const email = user["email"];
      localStorage.setItem(
        "user",
        JSON.stringify({ firstName, lastName, avatar: avatar["name"], email })
      );
      dispatch({
        type: UPDATE_USER,
        payloadData: {
          firstName,
          lastName,
          avatar: avatar["name"] ? avatar["name"] : avatar,
        },
      });
    } catch (error) {
      console.log("Error");
    }
  };
