import {
  FECTH_AMENITIES_REQUEST,
  FETCH_AMENITIES_FAIL,
  FETCH_AMENITIES_SUCCESS,
  FECTH_AMENITIES_HOTEL_REQUEST,
  FETCH_AMENITIES_HOTEL_FAIL,
  FETCH_AMENITIES_HOTEL_SUCCESS
} from "./amenitiesTypes";

const initialState = {
  amenities: [],
  amenities_hotel:[],
  loading: false,
  error: "",
};

export const amenitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FECTH_AMENITIES_REQUEST:
      return {
        ...state,
        amenities: [],
        loading: true,
        error: "",
      };

    case FETCH_AMENITIES_SUCCESS:
      return {
        ...state,
        amenities: action.payload,
        loading: false,
        error: "",
      };
    case FETCH_AMENITIES_FAIL:
      return {
        ...state,
        amenities: [],
        loading: false,
        error: action.payload,
      };
      case FECTH_AMENITIES_HOTEL_REQUEST:
      return {
        ...state,
        amenities_hotel: [],
        loading: true,
        error: "",
      };

    case FETCH_AMENITIES_HOTEL_SUCCESS:
      return {
        ...state,
        amenities_hotel: action.payload,
        loading: false,
        error: "",
      };
    case FETCH_AMENITIES_HOTEL_FAIL:
      return {
        ...state,
        amenities_hotel: [],
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
