/** @format */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wishlist: {
    wishlistRoom: [],
    wishlistHotel: [],
  },
  loading: false,
  error: "",
};

const wishlistSlice = createSlice({
  initialState,
  name: "wishlist",
  reducers: {
    fetchWishlistRequest(state) {
      state.loading = true;
    },
    fetchWishlistSuccess(state, action) {
      state.loading = false;
      state.wishlist = action.payload;
    },
    fetchWishlistFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const wishlistActions = wishlistSlice.actions;
export default wishlistSlice;
