/** @format */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MdLocationOn } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import HeartComponent from "../AssetComponents/HeartComponent";
import { useNavigate } from "react-router-dom";

function DetailCont(props) {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const hotelReducer = useSelector((state) => state.hotelReducer);
  const RoomsReducer = useSelector((state) => state.RoomsReducer);
  const handleCollapse = () => setIsCollapsed(!isCollapsed);
  useEffect(() => {
    if (props.rooms.length > 1) {
      setIsCollapsed(true);
    }
  }, [props.rooms]);

  return (
    <>
      <div
        className={
          props.available && props.type == "Room"
            ? "detail_container"
            : props.type == "Hotel"
            ? "detail_container"
            : "detail_container unavailable"
        }
      >
        <div className="detail_sub_container1">
          <img
            src={
              process.env.REACT_APP_API +
              (props.type == "Room" ? "/rooms/" : "/hotels/") +
              props.coverImage
            }
            onClick={() =>
              props.type == "Room"
                ? navigate("/hotel/" + props.hotel_id)
                : navigate("/hotel/" + props.Id)
            }
          />
        </div>
        {props.type == "Hotel" && (
          <div className="detail_sub_hotel">
            <div className="sub_hotel_header">
              <div className="sub_hotel_header_info">
                <div
                  className="sub_hotel_title"
                  onClick={() =>
                    props.type == "Room"
                      ? navigate("/hotel/" + props.hotel_id)
                      : navigate("/hotel/" + props.Id)
                  }
                >
                  {props.title}{" "}
                </div>

                <div className="sub_hotel_location">
                  <i>
                    <MdLocationOn size={"0.9rem"} />{" "}
                  </i>
                  {props.location}
                </div>
              </div>

              <HeartComponent Hotel_Id={props.Id} />
            </div>

            <div className="sub_hotel_cont2">
              {isCollapsed
                ? props.rooms[0] && (
                    <div className="sub_cont2_sub">
                      <div>{props.rooms[0].type}</div>
                      <div className="cont2_sub_price">
                        {" "}
                        ${props.rooms[0].price}
                        <span> /day </span>
                        <i onClick={() => navigate("/hotel/" + props.Id)}>
                          <MdOutlineKeyboardArrowRight size={"1.3rem"} />
                        </i>
                      </div>
                    </div>
                  )
                : props.rooms.map((room, i) => {
                    return (
                      <div className="sub_cont2_sub">
                        <div>{room.type}</div>
                        <div className="cont2_sub_price">
                          {" "}
                          ${room.price}
                          <span> /day </span>
                          <i onClick={() => navigate("/hotel/" + props.Id)}>
                            <MdOutlineKeyboardArrowRight size={"1.3rem"} />
                          </i>
                        </div>
                      </div>
                    );
                  })}
              {isCollapsed && (
                <div className="cont2_footer" onClick={handleCollapse}>
                  see all rooms
                </div>
              )}
            </div>
          </div>
        )}

        {props.type == "Room" && (
          <div className="detail_sub_room">
            <div className="sub_room_header">
              <div className="sub_hotel_header_info">
                <div className="sub_room_title">{props.title} </div>
                <div className="sub_room_location">
                  <i>
                    <MdLocationOn size={"0.8rem"} />{" "}
                  </i>
                  {props.location}
                </div>
              </div>

              <HeartComponent Room_Id={props.Id} />
            </div>
            <div className="sub_room_cont2">
              <div className="sub_cont2_container">
                <div className="sub_cont2_main_info">
                  <div className="sub_cont2_header">
                    <div className="title">{props.rooms.type} Room </div>
                    {/* <div className="subtitle">{props.rooms.description}</div> */}
                  </div>
                  <div className="cont2_sub_info">
                    <div className="amenties_list">
                      {props.rooms.amenities.map((av, i) => {
                        return (
                          <img
                            className={"amenityImg"}
                            src={
                              process.env.REACT_APP_API +
                              "/amenities/" +
                              av.icon
                            }
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                {!props.rooms.offer || props.rooms.offer == 0 ? (
                  <div className="cont2_sub_room_price">
                    ${props.rooms.price}
                    <span> /day </span>
                  </div>
                ) : (
                  <div className="cont2_room_price_offer">
                    <div className="cont2_sub_room_price">
                      $
                      {props.rooms.price -
                        props.rooms.price * (props.rooms.offer / 100)}
                      <span> /day </span>
                    </div>
                    <div className="room_offer_cont">
                      <div className="room_offer">-{props.rooms.offer}%</div>
                      <p>${props.rooms.price} per night</p>
                    </div>
                  </div>
                )}
              </div>

              <div
                className="cont2_room_footer"
                onClick={() => navigate("/hotel/" + props.hotel_id)}
              >
                {" "}
                see rooms from this hotel
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="detail_container_resp">
        {props.type == "Hotel" && (
          <div className="detail_sub_hotel">
            <div className="sub_hotel_header">
              <div className="detail_sub_container1">
                <img
                  src={
                    process.env.REACT_APP_API +
                    (props.type == "Room" ? "/rooms/" : "/hotels/") +
                    props.coverImage
                  }
                  onClick={() => navigate("/hotel/" + props.Id)}
                />
              </div>
              <div className="sub_hotel_header_info">
                <div className="sub_hotel_title">{props.title}</div>

                <div className="sub_hotel_location">
                  <i>
                    <MdLocationOn className="location_icon" size={"0.9rem"} />{" "}
                  </i>
                  {props.location}
                </div>
              </div>
              <HeartComponent Hotel_Id={props.Id} />
            </div>

            <div className="sub_hotel_cont2">
              {isCollapsed
                ? props.rooms[0] && (
                    <div className="sub_cont2_sub">
                      <div>{props.rooms[0].type} Room</div>
                      <div className="cont2_sub_price">
                        {" "}
                        ${props.rooms[0].price}
                        <span> /day </span>
                        <i>
                          <MdOutlineKeyboardArrowRight size={"1.3rem"} />
                        </i>
                      </div>
                    </div>
                  )
                : props.rooms.map((room, i) => {
                    return (
                      <div className="sub_cont2_sub">
                        <div>{room.type} Room</div>
                        <div className="cont2_sub_price">
                          {" "}
                          ${room.price}
                          <span> /day </span>
                          <i>
                            <MdOutlineKeyboardArrowRight size={"1.3rem"} />
                          </i>
                        </div>
                      </div>
                    );
                  })}
              {isCollapsed && (
                <div className="cont2_footer" onClick={handleCollapse}>
                  see all rooms
                </div>
              )}
            </div>
          </div>
        )}

        {props.type == "Room" && (
          <div className="detail_sub_room">
            <div className="sub_room_header">
              <div className="detail_sub_container1">
                <img
                  src={
                    process.env.REACT_APP_API +
                    (props.type == "Room" ? "/rooms/" : "/hotels/") +
                    props.coverImage
                  }
                />
              </div>
              <div className="sub_hotel_header_info">
                <div className="sub_room_title">{props.title} </div>
                <div className="sub_room_location">
                  <i>
                    <MdLocationOn size={"0.8rem"} />{" "}
                  </i>
                  {props.location}
                </div>
              </div>
              <HeartComponent Room_Id={props.rooms.Id} />
            </div>
            <div className="sub_room_cont2">
              <div className="sub_cont2_container">
                <div className="sub_cont2_main_info">
                  <div className="sub_cont2_header">
                    <div className="title">{props.rooms.title} </div>
                    <div className="subtitle">{props.rooms.payment}</div>
                  </div>
                  <div className="cont2_sub_info">
                    {props.rooms.amenities.map((av, i) => {
                      return (
                        <div className="cont2_sub_avail">
                          <img
                            src={
                              process.env.REACT_APP_API +
                              "/amenities/" +
                              av.icon
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
                {props.rooms.discount == 0 ? (
                  <div className="cont2_sub_room_price">
                    ${props.rooms.price}
                    <span> /day </span>
                  </div>
                ) : (
                  <div className="cont2_room_price_offer">
                    <div className="cont2_sub_room_price">
                      $
                      {props.rooms.price -
                        props.rooms.price * (props.rooms.discount / 100)}
                      <span> /day </span>
                    </div>
                    <div className="room_offer_cont">
                      <div className="room_offer">-{props.rooms.discount}%</div>
                      <p>${props.rooms.price} per night</p>
                    </div>
                  </div>
                )}
              </div>

              <div
                className="cont2_room_footer"
                onClick={() => {
                  props.hotelId && navigate("/hotel/" + props.hotelId);
                }}
              >
                {" "}
                see rooms from this hotel
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default DetailCont;
